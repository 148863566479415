// Generated by ts-to-zod
import { z } from 'zod'
import {
	AccountProvider,
	TotalCountLimit,
	CandidateState,
	ClientCandidateState,
	InternalCandidateState,
	EmailAccountType,
	EmailAddressFetchStatus,
	CandidateFlagEnumWithNegation,
	CandidateEmailAddressLabel,
	CandidateEmailAddressStatus,
	EmailAction,
	ClientDecision,
	order_by,
	order_direction,
	MoonhubLLM,
	ComparatorEnum,
	TemplateType,
	NotificationTemplateCode,
	Registrar,
	UserRole,
	DnsRecordType,
	DomainStatus,
	EducationDegreeEnum,
	EducationStatusEnum,
	InterestLevelModel,
	Tone,
	ExperienceStatusEnum,
	FundingStage,
	InviteStatus,
	status,
	TimezoneEnum,
	MatchTypeV2,
	NotificationChannel,
	NotificationStatus,
	RequirementCategoryType,
	SequenceStatus,
	SearchRunStatus,
	SearchRunScoreWorkflowState,
	SearchScoreWorkflowState,
} from './api-client.types.gen'

export const accountAllocationSchema = z.object({
	initial_outreach_load: z.number(),
	initial_outreach_load_proportion: z.string(),
	daily_initial_outreach_quota: z.number(),
	initial_outreach_load_remaining: z.number(),
})

export const accountPreviewSchema = z.object({
	account_id: z.string(),
	email_address: z.string(),
	daily_limit: z.number(),
})

export const accountProviderSchema = z.nativeEnum(AccountProvider)

export const addTeamMemberRequestSchema = z.object({
	user_id: z.string(),
})

export const apolloPeopleMatchRequestSchema = z.object({
	first_name: z.string().optional().nullable(),
	last_name: z.string().optional().nullable(),
	linkedin_url: z.string().optional().nullable(),
	company: z.string().optional().nullable(),
})

export const apolloPeopleMatchResultSchema = z.object({
	person: z.object({}).passthrough().optional().nullable(),
})

export const totalCountLimitSchema = z.nativeEnum(TotalCountLimit)

export const candidateMatchStatusSchema = z.object({
	status: z.string(),
	project_id: z.number(),
})

export const candidateStateSchema = z.nativeEnum(CandidateState)

export const clientCandidateStateSchema = z.nativeEnum(ClientCandidateState)

export const internalCandidateStateSchema = z.nativeEnum(InternalCandidateState)

export const baseModelSchema = z.object({}).passthrough()

export const jobSummarySchema = z.object({
	must_have: z.array(z.string()).nullable(),
	nice_to_have: z.array(z.string()).nullable(),
})

export const bulkEmailAddressFetchRequestSchema = z.object({
	candidate_ids: z.array(z.string()),
})

export const emailAccountTypeSchema = z.nativeEnum(EmailAccountType)

export const emailAddressFetchStatusSchema = z.nativeEnum(
	EmailAddressFetchStatus,
)

export const criteriaParametersSchema = z.object({
	require_security_clearance: z.boolean().optional().nullable(),
	require_ethnic_diversity: z.boolean().optional().nullable(),
	require_gender_diversity: z.boolean().optional().nullable(),
	require_veteran: z.boolean().optional().nullable(),
})

export const candidateFlagEnumWithNegationSchema = z.nativeEnum(
	CandidateFlagEnumWithNegation,
)

export const candidateEmailAddressLabelSchema = z.nativeEnum(
	CandidateEmailAddressLabel,
)

export const candidateEmailAddressStatusSchema = z.nativeEnum(
	CandidateEmailAddressStatus,
)

export const emailAddressSchema = z.object({
	id: z.number(),
	created_at: z.string(),
	email_address: z.string(),
})

export const candidateFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	external_ids: z.array(z.string()).optional().nullable(),
	owner_ids: z.array(z.string()).optional().nullable(),
	query: z.string().optional().nullable(),
})

export const candidateInfoUpdateSchema = z.object({
	email: z.string().optional().nullable(),
})

export const emailActionSchema = z.nativeEnum(EmailAction)

export const clientDecisionSchema = z.nativeEnum(ClientDecision)

export const candidateOutreachEmailSchema = z.object({
	personalization_text: z.string(),
})

export const organizationSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	deleted_at: z.string().optional().nullable(),
	name: z.string(),
	disable_sequence_eligibility: z.boolean(),
	website: z.string().optional().nullable(),
	mailing_address: z.string().optional().nullable(),
})

export const candidateMatchCountsSchema = z.object({
	counts: z.record(z.number()).optional(),
})

export const candidateMatchFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	message_id: z.string().optional().nullable(),
	job_ids: z.array(z.string()).optional().nullable(),
	candidate_ids: z.array(z.string()).optional().nullable(),
	state: candidateStateSchema.optional().nullable(),
	states: z.array(candidateStateSchema).optional().nullable(),
	client_state: clientCandidateStateSchema.optional().nullable(),
	client_states: z.array(clientCandidateStateSchema).optional().nullable(),
	internal_state: internalCandidateStateSchema.optional().nullable(),
	internal_states: z
		.array(internalCandidateStateSchema)
		.optional()
		.nullable(),
	reviewer_id: z.string().optional().nullable(),
	client_id: z.string().optional().nullable(),
	created_after: z.string().optional().nullable(),
	created_before: z.string().optional().nullable(),
	query: z.string().optional().nullable(),
})

export const candidateMatchInternalCountsSchema = z.object({
	counts: z.record(z.number()).optional(),
})

export const orderBySchema = z.nativeEnum(order_by)

export const orderDirectionSchema = z.nativeEnum(order_direction)

export const candidateMatchOrderingSchema = z.object({
	order_by: z
		.union([
			z.literal('created_at'),
			z.literal('updated_at'),
			z.literal('state'),
			z.literal('view_count'),
			z.literal('reminder_count'),
		])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const candidateMatchTransitionFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	match_ids: z.array(z.string()).optional().nullable(),
	from_states: z.array(candidateStateSchema).optional().nullable(),
	to_states: z.array(candidateStateSchema).optional().nullable(),
})

export const candidateMatchTransitionOrderingSchema = z.object({
	order_by: z.literal('timestamp').optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const candidateOrderingSchema = z.object({
	order_by: z
		.union([
			z.literal('created_at'),
			z.literal('first_name'),
			z.literal('last_name'),
			z.literal('job_title'),
		])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const candidateScoreFiltersSchema = z.object({
	search_id: z.string().optional().nullable(),
	search_run_id: z.string().optional().nullable(),
	candidate_external_ids: z.array(z.string()).optional().nullable(),
})

export const candidateScoreOrderingSchema = z.object({
	order_by: z
		.union([
			z.literal('must_score'),
			z.literal('must_perfect_pred_count'),
			z.literal('nice_score'),
			z.literal('nice_perfect_pred_count'),
			z.literal('es_score'),
			z.literal('candidate_name'),
		])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const moonhubLLMSchema = z.nativeEnum(MoonhubLLM)

export const candidateStateTransitionSchema = z.object({
	id: z.string(),
	match_id: z.string(),
	from_state: candidateStateSchema,
	to_state: candidateStateSchema,
	timestamp: z.string(),
	transition_metadata: z.object({}).passthrough().optional().nullable(),
})

export const candidateScoringLLMConfigSchema = z.object({
	model_type: moonhubLLMSchema.optional().nullable(),
	application_name: z.string().optional().nullable(),
	temperature: z.number().optional().nullable(),
	max_tokens: z.number().optional().nullable(),
	request_timeout: z.number().optional().nullable(),
})

export const candidateToScoreV2ConfigOutputSchema = z.object({
	use_rag: z.boolean().optional().nullable(),
	use_cache: z.boolean().optional().nullable(),
	custom_instructions: z.string().optional().nullable(),
	llm: candidateScoringLLMConfigSchema.optional().nullable(),
})

export const comparatorEnumSchema = z.nativeEnum(ComparatorEnum)

export const nullableUpdateMetaSchema = z.object({
	new_raw_records: z.array(z.string().nullable()).optional().nullable(),
	last_update_build_id: z.number().optional().nullable(),
})

export const nullableCitationsForYearSchema = z.object({
	year: z.number().optional().nullable(),
	citations: z.number().optional().nullable(),
})

export const nullableGScholarArticleSchema = z.object({
	title: z.string().optional().nullable(),
	citations: z.number().optional().nullable(),
	year: z.number().optional().nullable(),
})

export const nullableEducationSchema = z.object({
	degree: z.string().optional().nullable(),
	university: z.string().optional().nullable(),
	university_domain: z.string().optional().nullable(),
	field: z.string().optional().nullable(),
	start_date: z.string().optional().nullable(),
	end_date: z.string().optional().nullable(),
	current: z.boolean().optional().nullable(),
	recent: z.boolean().optional().nullable(),
})

export const nullableExperienceSchema = z.object({
	title: z.string().optional().nullable(),
	description: z.string().optional().nullable(),
	company_mhid: z.string().optional().nullable(),
	company_id: z.number().optional().nullable(),
	company: z.string().optional().nullable(),
	company_logo: z.string().optional().nullable(),
	company_linkedin_url: z.string().optional().nullable(),
	company_website: z.string().optional().nullable(),
	company_industries: z.array(z.string().nullable()).optional().nullable(),
	company_tags: z.array(z.string().nullable()).optional().nullable(),
	company_founded_year: z.number().optional().nullable(),
	company_size: z.number().optional().nullable(),
	company_raised_total: z.number().optional().nullable(),
	company_stages: z.array(z.string().nullable()).optional().nullable(),
	company_investors: z.array(z.string().nullable()).optional().nullable(),
	company_investing_partners: z
		.array(z.string().nullable())
		.optional()
		.nullable(),
	locality: z.string().optional().nullable(),
	start_date: z.string().optional().nullable(),
	end_date: z.string().optional().nullable(),
	current: z.boolean().optional().nullable(),
	recent: z.boolean().optional().nullable(),
	years: z.number().optional().nullable(),
	employee_count_when_joined: z.number().optional().nullable(),
	peak_employee_count_during_tenure: z.number().optional().nullable(),
	peak_annual_growth_rate_during_tenure: z.number().optional().nullable(),
	limited_peak_annual_growth_rate_during_tenure: z
		.number()
		.optional()
		.nullable(),
	company_stage_when_joined: z.string().optional().nullable(),
	company_stage_when_left: z.string().optional().nullable(),
})

export const nullableRoleHistorySchema = z.object({
	title: z.string().optional().nullable(),
	years: z.number().optional().nullable(),
})

export const nullableCompanyHistorySchema = z.object({
	name: z.string().optional().nullable(),
	years: z.number().optional().nullable(),
})

export const nullableCourseSchema = z.object({
	title: z.string().optional().nullable(),
	issuer: z.string().optional().nullable(),
})

export const nullableCertificationSchema = z.object({
	title: z.string().optional().nullable(),
	issuer: z.string().optional().nullable(),
})

export const nullableLanguageSchema = z.object({
	name: z.string().optional().nullable(),
	proficiency: z.string().optional().nullable(),
})

export const nullableRecommendationSchema = z.object({
	recommender_linkedin_profile_id: z.number().optional().nullable(),
	recommendation: z.string().optional().nullable(),
	recommender_name: z.string().optional().nullable(),
})

export const nullableArticleSchema = z.object({
	title: z.string().optional().nullable(),
	date_published: z.string().optional().nullable(),
})

export const nullableAwardSchema = z.object({
	title: z.string().optional().nullable(),
	year: z.number().optional().nullable(),
	company_id: z.number().optional().nullable(),
	summary: z.string().optional().nullable(),
	company_name: z.string().optional().nullable(),
})

export const nullablePatentSchema = z.object({
	title: z.string().optional().nullable(),
	year: z.number().optional().nullable(),
	patent_id: z.string().optional().nullable(),
	country: z.string().optional().nullable(),
	description: z.string().optional().nullable(),
	url: z.string().optional().nullable(),
})

export const nullableProjectSchema = z.object({
	title: z.string().optional().nullable(),
	association: z.string().optional().nullable(),
	id: z.number().optional().nullable(),
	summary: z.string().optional().nullable(),
	url: z.string().optional().nullable(),
	is_current: z.boolean().optional().nullable(),
	start_date: z.string().optional().nullable(),
	end_date: z.string().optional().nullable(),
})

export const nullableVolunteeringSchema = z.object({
	role: z.string().optional().nullable(),
	cause: z.string().optional().nullable(),
	company_id: z.number().optional().nullable(),
	company_name: z.string().optional().nullable(),
	current: z.boolean().optional().nullable(),
	start_year: z.number().optional().nullable(),
	end_year: z.number().optional().nullable(),
})

export const nullablePublicationSchema = z.object({
	title: z.string().optional().nullable(),
	var_date: z.string().optional().nullable(),
	summary: z.string().optional().nullable(),
	publisher: z.string().optional().nullable(),
	url: z.string().optional().nullable(),
})

export const nullableStatsSchema = z.object({
	job_title_length: z.number().optional().nullable(),
	job_description_length: z.number().optional().nullable(),
	experience_count: z.number().optional().nullable(),
	experience_total_length: z.number().optional().nullable(),
	education_count: z.number().optional().nullable(),
	education_total_length: z.number().optional().nullable(),
	courses_count: z.number().optional().nullable(),
	certifications_count: z.number().optional().nullable(),
})

export const createCandidateEmailAddressItemParamsSchema = z.object({
	candidate_id: z.string(),
	email_address: z.string().optional().nullable(),
	email_address_id: z.number().optional().nullable(),
	label: candidateEmailAddressLabelSchema.optional().nullable(),
	status: candidateEmailAddressStatusSchema.optional().nullable(),
})

export const createCandidateRequestSchema = z.object({
	external_id: z.string(),
	first_name: z.string().optional().nullable(),
	last_name: z.string().optional().nullable(),
	job_title: z.string().optional().nullable(),
	current_company: z.string().optional().nullable(),
	locality: z.string().optional().nullable(),
	phone_number: z.string().optional().nullable(),
	candidate_tags: z.array(z.string()).optional().nullable(),
	search_criteria: z.record(z.array(z.string())).optional().nullable(),
	notes: z.string().optional().nullable(),
	min_comp_expectations: z.number().optional().nullable(),
	max_comp_expectations: z.number().optional().nullable(),
	equity_expectations: z.number().optional().nullable(),
})

export const createEmailGroupParamsSchema = z.object({
	org_id: z.string(),
	name: z.string(),
})

export const createJobRequestSchema = z.object({
	name: z.string(),
	job_title: z.string(),
	company_name: z.string(),
	organization_id: z.string(),
	weekly_outreach_quota: z.number().optional().nullable(),
	status: z
		.union([
			z.literal('Active'),
			z.literal('Passive'),
			z.literal('Paused'),
			z.literal('Won'),
			z.literal('Lost'),
		])
		.optional()
		.nullable(),
	job_description: z.string().optional().nullable(),
	client_intake_notes: z.string().optional().nullable(),
	recruiter_notes: z.string().optional().nullable(),
	links: z.record(z.string()).optional().nullable(),
	hiring_manager_name: z.string().optional().nullable(),
})

export const createJobSummaryRequestSchema = z.object({
	must_have: z.array(z.string()).nullable(),
	nice_to_have: z.array(z.string()).nullable(),
})

export const templateTypeSchema = z.nativeEnum(TemplateType)

export const notificationTemplateCodeSchema = z.nativeEnum(
	NotificationTemplateCode,
)

export const createOrganizationRequestSchema = z.object({
	name: z.string(),
	website: z.string().optional().nullable(),
	mailing_address: z.string().optional().nullable(),
})

export const registrarSchema = z.nativeEnum(Registrar)

export const goDaddyCredentialsSchema = z.object({
	type: z.literal('GODADDY').optional(),
	api_key: z.string(),
	api_secret: z.string(),
	customer_number: z.string(),
	customer_id: z.string(),
})

export const route53CredentialsSchema = z.object({
	type: z.literal('ROUTE53').optional(),
	access_key: z.string(),
	secret_key: z.string(),
	aws_region: z.string(),
	session_token: z.string().optional().nullable(),
	iam_role_arn: z.string().optional().nullable(),
})

export const createScheduledSequenceFromTemplateRequestSchema = z.object({
	template_id: z.string(),
	candidate_id: z.string(),
	job_id: z.string(),
	user_email_address_id: z.string(),
})

export const templateStepSchema = z.object({
	subject: z.string(),
	markdown: z.string(),
	seconds_from_now: z.number(),
})

export const createSearchRunRequestSchema = z.object({
	job_id: z.string(),
})

export const createTemplateRequestSchema = z.object({
	name: z.string(),
	template_steps: z.array(templateStepSchema),
	organization_id: z.string(),
	job_id: z.string().optional().nullable(),
})

export const createUnmanagedDomainParamsSchema = z.object({
	domain_name: z.string(),
})

export const createUserEmailAliasParamsSchema = z.object({
	email_account_id: z.string(),
	email_address: z.string(),
})

export const userRoleSchema = z.nativeEnum(UserRole)

export const createUserRequestSchema = z.object({
	name: z.string(),
	picture: z.string().optional().nullable(),
	invite_id: z.string().optional().nullable(),
})

export const dailyEmailAnalyticsSchema = z.object({
	date: z.string(),
	total_sent_emails: z.number(),
	sequence_matches: z.number(),
})

export const dailyStatsPreviewSchema = z.object({
	account_daily_sent: z.record(z.number()),
	account_daily_initial_outreach_sent: z.record(z.number()),
})

export const dateQuerySchema = z.object({
	date_1: z.string(),
	comparator: comparatorEnumSchema.optional().nullable(),
	date_2: z.string().optional().nullable(),
})

export const deleteMatchesRequestSchema = z.object({
	job_id: z.string(),
	candidate_ids: z.array(z.string()).optional().nullable(),
	external_candidate_ids: z.array(z.string()).optional().nullable(),
})

export const dnsRecordTypeSchema = z.nativeEnum(DnsRecordType)

export const dnsRecordFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	domain_ids: z.array(z.string()).optional().nullable(),
	types: z.array(dnsRecordTypeSchema).optional().nullable(),
	names: z.array(z.string()).optional().nullable(),
	query: z.string().optional().nullable(),
})

export const dnsRecordOrderingSchema = z.object({
	order_by: z
		.union([
			z.literal('created_at'),
			z.literal('name'),
			z.literal('type'),
			z.literal('updated_at'),
		])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const domainStatusSchema = z.nativeEnum(DomainStatus)

export const domainContactSchema = z.object({
	name: z.string().optional().nullable(),
	organization: z.string().optional().nullable(),
	email: z.string().optional().nullable(),
	phone: z.string().optional().nullable(),
	address1: z.string().optional().nullable(),
	address2: z.string().optional().nullable(),
	city: z.string().optional().nullable(),
	state: z.string().optional().nullable(),
	postal_code: z.string().optional().nullable(),
	country: z.string().optional().nullable(),
})

export const domainOrderingSchema = z.object({
	order_by: z
		.union([
			z.literal('created_at'),
			z.literal('domain_name'),
			z.literal('status'),
			z.literal('expires_at'),
			z.literal('registrar_created_at'),
		])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const domainWithMetricsSchema = z.object({
	id: z.string(),
	domain_name: z.string(),
	status: domainStatusSchema,
	registrar_account_id: z.string().optional().nullable(),
	registrar_domain_id: z.string().optional().nullable(),
	contact_admin: domainContactSchema.optional().nullable(),
	contact_billing: domainContactSchema.optional().nullable(),
	contact_registrant: domainContactSchema.optional().nullable(),
	contact_tech: domainContactSchema.optional().nullable(),
	expiration_protected: z.boolean(),
	transfer_protected: z.boolean(),
	privacy_enabled: z.boolean(),
	locked: z.boolean(),
	auto_renew: z.boolean(),
	expose_whois: z.boolean(),
	name_servers: z.array(z.string()),
	created_at: z.string(),
	updated_at: z.string(),
	deleted_at: z.string().optional().nullable(),
	expires_at: z.string().optional().nullable(),
	renew_deadline: z.string().optional().nullable(),
	transfer_eligible_at: z.string().optional().nullable(),
	registrar_created_at: z.string().optional().nullable(),
	last_synced_at: z.string().optional().nullable(),
	organization_id: z.string().optional().nullable(),
	dns_records_count: z.number(),
})

export const educationDegreeEnumSchema = z.nativeEnum(EducationDegreeEnum)

export const educationStatusEnumSchema = z.nativeEnum(EducationStatusEnum)

export const educationParametersOutputSchema = z.object({
	status: educationStatusEnumSchema.optional().nullable(),
	universities: z.array(z.string()).optional().nullable(),
	degree: z.array(educationDegreeEnumSchema).optional().nullable(),
	subject: z.array(z.string()).optional().nullable(),
	start_date: dateQuerySchema.optional().nullable(),
	end_date: dateQuerySchema.optional().nullable(),
})

export const emailAccountAnalyticsParamsSchema = z.object({
	start_date: z.string(),
	end_date: z.string().optional().nullable(),
	timezone: z.string().optional(),
	account_ids: z.array(z.string()),
})

export const emailAccountMetricsSchema = z.object({
	id: z.string(),
	is_warm: z.boolean(),
	email_address: z.string(),
	account_type: emailAccountTypeSchema,
	daily_limit: z.number(),
	deliverability: z.number().nullable(),
})

export const emailAddressFetchResponseSchema = z.object({
	email_addresses: z.array(z.string()),
	data: z.object({}).passthrough().nullable(),
})

export const emailAddressFetchFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	candidate_ids: z.array(z.string()).optional().nullable(),
	workflow_run_ids: z.array(z.string()).optional().nullable(),
	statuses: z.array(emailAddressFetchStatusSchema).optional().nullable(),
})

export const emailAddressFetchOrderingSchema = z.object({
	order_by: z
		.union([z.literal('created_at'), z.literal('updated_at')])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const emailAddressFetchRequestSchema = z.object({
	candidate_id: z.string(),
})

export const interestLevelModelSchema = z.nativeEnum(InterestLevelModel)

export const toneSchema = z.nativeEnum(Tone)

export const emailAnalyticsByDaySchema = z.object({
	date: z.string(),
	total_messages: z.number(),
	warm_messages: z.number(),
	non_warm_messages: z.number(),
	daily_limit: z.number(),
	warm_message_ratio: z.number(),
})

export const emailGroupSchema = z.object({
	created_at: z.string(),
	id: z.string(),
	org_id: z.string(),
	name: z.string(),
})

export const emailGroupAnalyticsParamsSchema = z.object({
	start_date: z.string(),
	end_date: z.string().optional().nullable(),
	timezone: z.string().optional(),
	email_group_ids: z.array(z.string()),
})

export const emailGroupFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	email_group_ids: z.array(z.string()).optional().nullable(),
	email_group_names: z.array(z.string()).optional().nullable(),
	org_ids: z.array(z.string()).optional().nullable(),
	deactivated: z.boolean().optional().nullable(),
	disconnected: z.boolean().optional().nullable(),
	warm: z.boolean().optional().nullable(),
	query: z.string().optional().nullable(),
})

export const emailGroupItemSchema = z.object({
	email_group_id: z.string(),
	user_email_account_id: z.string(),
	created_at: z.string(),
})

export const emailGroupItemBatchParamsSchema = z.object({
	email_group_id: z.string(),
	user_email_account_ids: z.array(z.string()).optional().nullable(),
	email_addresses: z.array(z.string()).optional().nullable(),
})

export const emailGroupItemParamsSchema = z.object({
	email_group_id: z.string(),
	user_email_account_id: z.string(),
})

export const emailGroupOrderingSchema = z.object({
	order_by: z.union([z.literal('created_at'), z.literal('name')]).optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const emailGroupWithMetricsSchema = z.object({
	created_at: z.string(),
	id: z.string(),
	org_id: z.string(),
	name: z.string(),
	active_email_accounts_count: z.number(),
})

export const nylasEmailNameSchema = z.object({
	email: z.string(),
	name: z.string().optional().nullable(),
})

export const emailMessageAttachmentMetadataSchema = z.object({
	id: z.string(),
	content_ref: z.string(),
	content_type: z.string(),
	filename: z.string().optional().nullable(),
	size: z.number(),
})

export const nylasMessageHeaderSchema = z.object({
	name: z.string(),
	value: z.string(),
})

export const emailMessageAttachmentWithUrlSchema = z.object({
	id: z.string(),
	content_ref: z.string(),
	content_type: z.string(),
	filename: z.string().optional().nullable(),
	size: z.number(),
	url: z.string(),
})

export const emailMessageFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	unread: z.boolean().optional().nullable(),
	folder: z.string().optional().nullable(),
	starred: z.boolean().optional().nullable(),
	sender_emails: z.array(z.string()).optional().nullable(),
	recipient_emails: z.array(z.string()).optional().nullable(),
	any_email: z.array(z.string()).optional().nullable(),
	thread_ids: z.array(z.string()).optional().nullable(),
	after_date: z.string().optional().nullable(),
	before_date: z.string().optional().nullable(),
	header_message_ids: z.array(z.string()).optional().nullable(),
	header_in_reply_to: z.array(z.string()).optional().nullable(),
	is_thread_id_resolved: z.boolean().optional().nullable(),
	sender_job_id: z.string().optional().nullable(),
})

export const emailMessageOrderingSchema = z.object({
	order_by: z
		.union([
			z.literal('created_at'),
			z.literal('updated_at'),
			z.literal('date'),
		])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const emailAnalysisResponseV2Schema = z.object({
	interest_level: interestLevelModelSchema,
	questions: z.array(z.string()).optional().nullable(),
	candidate_info_update: candidateInfoUpdateSchema.optional().nullable(),
	tone: toneSchema,
	wrong_email: z.boolean().optional().nullable(),
})

export const emailThreadSchema = z.object({
	thread_id: z.string(),
	email_count: z.number(),
	latest_date: z.string(),
	subject: z.string(),
	email_addresses: z.array(z.string()),
	snippet: z.string().nullable(),
})

export const emailThreadFiltersSchema = z.object({
	candidate_id: z.string(),
})

export const experienceStatusEnumSchema = z.nativeEnum(ExperienceStatusEnum)

export const numericQuerySchema = z.object({
	value_1: z.number(),
	comparator: comparatorEnumSchema.optional().nullable(),
	value_2: z.number().optional().nullable(),
})

export const fundingStageSchema = z.nativeEnum(FundingStage)

export const fundingRoundRangeQuerySchema = z.object({
	value_1: fundingStageSchema,
	comparator: comparatorEnumSchema.optional().nullable(),
	value_2: fundingStageSchema.optional().nullable(),
})

export const experienceParametersOutputSchema = z.object({
	status: experienceStatusEnumSchema.optional().nullable(),
	titles: z.array(z.string()).optional().nullable(),
	companies: z.array(z.string()).optional().nullable(),
	start_date: dateQuerySchema.optional().nullable(),
	end_date: dateQuerySchema.optional().nullable(),
	num_employees: numericQuerySchema.optional().nullable(),
	investors: z.array(z.string()).optional().nullable(),
	partners: z.array(z.string()).optional().nullable(),
	raised_total: numericQuerySchema.optional().nullable(),
	founded_year: numericQuerySchema.optional().nullable(),
	funding_round: z.array(fundingStageSchema).optional().nullable(),
	industry: z.array(z.string()).optional().nullable(),
	funding_round_when_joined: fundingRoundRangeQuerySchema
		.optional()
		.nullable(),
	funding_round_when_left: fundingRoundRangeQuerySchema.optional().nullable(),
	company_size_when_joined: numericQuerySchema.optional().nullable(),
	peak_company_size_during_tenure: numericQuerySchema.optional().nullable(),
	peak_annual_growth_during_tenure: z.number().optional().nullable(),
	description_keywords: z.array(z.string()).optional().nullable(),
	num_years: numericQuerySchema.optional().nullable(),
})

export const educationParametersInputSchema = z.object({
	status: educationStatusEnumSchema.optional().nullable(),
	universities: z.array(z.string()).optional().nullable(),
	degree: z.array(educationDegreeEnumSchema).optional().nullable(),
	subject: z.array(z.string()).optional().nullable(),
	start_date: dateQuerySchema.optional().nullable(),
	end_date: dateQuerySchema.optional().nullable(),
})

export const experienceParametersInputSchema = z.object({
	status: experienceStatusEnumSchema.optional().nullable(),
	titles: z.array(z.string()).optional().nullable(),
	companies: z.array(z.string()).optional().nullable(),
	start_date: dateQuerySchema.optional().nullable(),
	end_date: dateQuerySchema.optional().nullable(),
	num_employees: numericQuerySchema.optional().nullable(),
	investors: z.array(z.string()).optional().nullable(),
	partners: z.array(z.string()).optional().nullable(),
	raised_total: numericQuerySchema.optional().nullable(),
	founded_year: numericQuerySchema.optional().nullable(),
	funding_round: z.array(fundingStageSchema).optional().nullable(),
	industry: z.array(z.string()).optional().nullable(),
	funding_round_when_joined: fundingRoundRangeQuerySchema
		.optional()
		.nullable(),
	funding_round_when_left: fundingRoundRangeQuerySchema.optional().nullable(),
	company_size_when_joined: numericQuerySchema.optional().nullable(),
	peak_company_size_during_tenure: numericQuerySchema.optional().nullable(),
	peak_annual_growth_during_tenure: z.number().optional().nullable(),
	description_keywords: z.array(z.string()).optional().nullable(),
	num_years: numericQuerySchema.optional().nullable(),
})

export const candidateDetailsCollapseSchema = z.object({
	name: z.string().optional().nullable(),
	criteria: criteriaParametersSchema.optional().nullable(),
	flags: z.array(candidateFlagEnumWithNegationSchema).optional().nullable(),
})

export const fieldNotFoundErrorItemSchema = z.object({
	entity_name: z.string(),
	entity_id: z.string(),
	field: z.string(),
})

export const githubParametersInputSchema = z.object({
	has_github_profile: z.boolean().optional().nullable(),
	github_repositories: z.array(z.string()).optional().nullable(),
	num_contributions: numericQuerySchema.optional().nullable(),
})

export const githubParametersOutputSchema = z.object({
	has_github_profile: z.boolean().optional().nullable(),
	github_repositories: z.array(z.string()).optional().nullable(),
	num_contributions: numericQuerySchema.optional().nullable(),
})

export const highlightedJobSummaryRequirementSchema = z.object({
	id: z.string(),
	value: z.string(),
	highlight: z.boolean().optional(),
})

export const humanReviewRequestSchema = z.object({
	override_action: emailActionSchema.optional().nullable(),
	decline_email: candidateOutreachEmailSchema.optional().nullable(),
	introduction_email: candidateOutreachEmailSchema.optional().nullable(),
	reject_email: candidateOutreachEmailSchema.optional().nullable(),
	notes: z.string().optional().nullable(),
})

export const inviteStatusSchema = z.nativeEnum(InviteStatus)

export const statusSchema = z.nativeEnum(status)

export const jobSummaryOutputSchema = z.object({
	must_have: z.array(z.string()).nullable(),
	nice_to_have: z.array(z.string()).nullable(),
	id: z.string(),
	created_at: z.string(),
})

export const jobOrderingSchema = z.object({
	order_by: z
		.union([
			z.literal('created_at'),
			z.literal('name'),
			z.literal('job_title'),
			z.literal('company_name'),
		])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const sequenceMetricsSchema = z.object({
	total_candidates_outreached: z.number(),
	total_emails_sent: z.number(),
	total_candidates_replied: z.number(),
	total_candidates_submitted: z.number(),
	total_candidates_introduced: z.number(),
	candidates_in_queue: z.number(),
	candidates_in_sequence: z.number(),
	reply_rate: z.number(),
	interest_rate: z.number(),
})

export const replyMetricsSchema = z.object({
	reply_date: z.string(),
	replied: z.number(),
	interested: z.number(),
	accepted: z.number(),
})

export const templateMetricsSchema = z.object({
	id: z.string(),
	template_name: z.string(),
	sequences_delivered: z.number(),
	candidates_outreached: z.number(),
	candidates_replied: z.number(),
	reply_rate: z.number(),
})

export const jobQuotaDataSchema = z.object({
	weekly_initial_outreach_quota: z.number(),
	weekly_initial_outreach_sent: z.number(),
	weekly_initial_outreach_remaining: z.number(),
	daily_initial_outreach_quota: z.number(),
})

export const jobSequenceQueueMetricSchema = z.object({
	organization_id: z.string(),
	organization_name: z.string(),
	job_id: z.string(),
	job_name: z.string(),
	queued_sequences: z.number(),
	days_remaining: z.number(),
})

export const jobSequencesPreviewSchema = z.object({
	initial_outreach: z.number(),
	follow_ups: z.number(),
})

export const paginationInputSchema = z.object({
	page: z.number().optional(),
	page_size: z.number().optional(),
})

export const paginationOutputSchema = z.object({
	page: z.number().optional(),
	page_size: z.number().optional(),
	total_count: z.number(),
})

export const listCandidateMatchParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(candidateMatchOrderingSchema).optional(),
	filters: candidateMatchFiltersSchema.optional(),
	include_candidate: z.boolean().optional().nullable(),
	include_sequence_template: z.boolean().optional().nullable(),
})

export const listCandidateMatchParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(candidateMatchOrderingSchema).optional(),
	filters: candidateMatchFiltersSchema.optional(),
	include_candidate: z.boolean().optional().nullable(),
	include_sequence_template: z.boolean().optional().nullable(),
})

export const listCandidateMatchTransitionsParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(candidateMatchTransitionOrderingSchema).optional(),
	filters: candidateMatchTransitionFiltersSchema.optional(),
})

export const listCandidateMatchTransitionsParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(candidateMatchTransitionOrderingSchema).optional(),
	filters: candidateMatchTransitionFiltersSchema.optional(),
})

export const listCandidateParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(candidateOrderingSchema).optional(),
	filters: candidateFiltersSchema.optional(),
})

export const listCandidateParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(candidateOrderingSchema).optional(),
	filters: candidateFiltersSchema.optional(),
})

export const listCandidateScoreParamsSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(candidateScoreOrderingSchema).optional(),
	filters: candidateScoreFiltersSchema.optional(),
	include_matched_candidates: z.boolean().optional().nullable(),
})

export const listDnsRecordParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(dnsRecordOrderingSchema).optional(),
	filters: dnsRecordFiltersSchema.optional(),
})

export const listDnsRecordParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(dnsRecordOrderingSchema).optional(),
	filters: dnsRecordFiltersSchema.optional(),
})

export const listDomainFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	query: z.string().optional().nullable(),
	registrar_account_ids: z.array(z.string()).optional().nullable(),
	registrars: z.array(registrarSchema).optional().nullable(),
	statuses: z.array(domainStatusSchema).optional().nullable(),
	domain_names: z.array(z.string()).optional().nullable(),
	include_deleted: z.boolean().optional().nullable(),
	organization_ids: z.array(z.string()).optional().nullable(),
})

export const listDomainParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(domainOrderingSchema).optional(),
	filters: listDomainFiltersSchema.optional(),
	include_metrics: z.boolean().optional().nullable(),
})

export const listDomainParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(domainOrderingSchema).optional(),
	filters: listDomainFiltersSchema.optional(),
	include_metrics: z.boolean().optional().nullable(),
})

export const listEmailGroupParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(emailGroupOrderingSchema).optional(),
	filters: emailGroupFiltersSchema.optional(),
	include_metrics: z.boolean().optional().nullable(),
})

export const listEmailGroupParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(emailGroupOrderingSchema).optional(),
	filters: emailGroupFiltersSchema.optional(),
	include_metrics: z.boolean().optional().nullable(),
})

export const listEmailMessageParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(emailMessageOrderingSchema).optional(),
	filters: emailMessageFiltersSchema.optional(),
	load_body: z.boolean().optional(),
})

export const listEmailMessageParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(emailMessageOrderingSchema).optional(),
	filters: emailMessageFiltersSchema.optional(),
	load_body: z.boolean().optional(),
})

export const orderingSchema = z.object({
	order_by: z.string(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const listEmailThreadParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(orderingSchema).optional(),
	filters: emailThreadFiltersSchema,
})

export const listJobFiltersSchema = z.object({
	organization_id: z.string().optional().nullable(),
	ids: z.array(z.string()).optional().nullable(),
	owner_ids: z.array(z.string()).optional().nullable(),
	statuses: z
		.array(
			z.union([
				z.literal('Active'),
				z.literal('Passive'),
				z.literal('Paused'),
				z.literal('Won'),
				z.literal('Lost'),
			]),
		)
		.optional()
		.nullable(),
	candidate_id: z.string().optional().nullable(),
	query: z.string().optional().nullable(),
	team_member_ids: z.array(z.string()).optional().nullable(),
	owner_or_team_member_ids: z.array(z.string()).optional().nullable(),
})

export const listJobParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(jobOrderingSchema).optional(),
	filters: listJobFiltersSchema.optional(),
	include_metrics: z.boolean().optional().nullable(),
})

export const listJobParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(jobOrderingSchema).optional(),
	filters: listJobFiltersSchema.optional(),
	include_metrics: z.boolean().optional().nullable(),
})

export const notificationOrderingSchema = z.object({
	order_by: z
		.union([
			z.literal('created_at'),
			z.literal('sent_at'),
			z.literal('status'),
		])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const notificationTemplateOrderingSchema = z.object({
	order_by: z
		.union([
			z.literal('created_at'),
			z.literal('name'),
			z.literal('version'),
			z.literal('type'),
		])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const notificationTemplateFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	type: templateTypeSchema.optional().nullable(),
	name_query: z.string().optional().nullable(),
	tags: z.array(z.string()).optional().nullable(),
	is_active: z.boolean().optional().nullable(),
})

export const listNotificationTemplateParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(notificationTemplateOrderingSchema).optional(),
	filters: notificationTemplateFiltersSchema.optional(),
})

export const listOrganizationFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	query: z.string().optional().nullable(),
})

export const organizationOrderingSchema = z.object({
	order_by: z.union([z.literal('created_at'), z.literal('name')]).optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const listOrganizationParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(organizationOrderingSchema).optional(),
	filters: listOrganizationFiltersSchema.optional(),
	include_metrics: z.boolean().optional().nullable(),
})

export const listRegistrarFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	query: z.string().optional().nullable(),
	registrars: z.array(registrarSchema).optional().nullable(),
	include_deleted: z.boolean().optional().nullable(),
})

export const registrarOrderingSchema = z.object({
	order_by: z
		.union([
			z.literal('created_at'),
			z.literal('name'),
			z.literal('last_synced_at'),
			z.literal('registrar'),
		])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const listRegistrarParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(registrarOrderingSchema).optional(),
	filters: listRegistrarFiltersSchema.optional(),
	include_metrics: z.boolean().optional(),
})

export const scheduledSequenceOrderingSchema = z.object({
	order_by: z
		.union([
			z.literal('created_at'),
			z.literal('updated_at'),
			z.literal('sender_email'),
			z.literal('recipient_email'),
			z.literal('subject'),
			z.literal('status'),
			z.literal('num_steps_completed'),
			z.literal('next_deliver_date'),
		])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const listSearchFiltersSchema = z.object({
	job_ids: z.array(z.string()).optional().nullable(),
})

export const searchOrderingSchema = z.object({
	order_by: z
		.union([z.literal('created_at'), z.literal('updated_at')])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const listSearchParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(searchOrderingSchema).optional(),
	filters: listSearchFiltersSchema.optional(),
})

export const listSearchRunFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	job_id: z.string().optional().nullable(),
})

export const searchRunOrderingSchema = z.object({
	order_by: z
		.union([z.literal('created_at'), z.literal('updated_at')])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const listSearchRunParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(searchRunOrderingSchema).optional(),
	filters: listSearchRunFiltersSchema.optional(),
})

export const sequenceTemplateOrderingSchema = z.object({
	order_by: z
		.union([z.literal('created_at'), z.literal('updated_at')])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const sequenceTemplateFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	org_ids: z.array(z.string()).optional().nullable(),
	name_query: z.string().optional().nullable(),
	job_ids: z.array(z.string()).optional().nullable(),
})

export const listSequenceTemplateParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(sequenceTemplateOrderingSchema).optional(),
	filters: sequenceTemplateFiltersSchema.optional(),
})

export const userEmailAccountOrderingSchema = z.object({
	order_by: z
		.union([
			z.literal('created_at'),
			z.literal('email_address'),
			z.literal('daily_limit'),
			z.literal('inbox_warm'),
			z.literal('provider'),
		])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const userEmailAccountFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	user_id: z.string().optional().nullable(),
	active: z.boolean().optional().nullable(),
	email_addresses: z.array(z.string()).optional().nullable(),
	external_ids: z.array(z.string()).optional().nullable(),
	email_group_ids: z.array(z.string()).optional().nullable(),
	query: z.string().optional().nullable(),
})

export const listUserEmailAccountParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(userEmailAccountOrderingSchema).optional(),
	filters: userEmailAccountFiltersSchema.optional(),
	include_warmy: z.boolean().optional(),
})

export const userEmailAddressOrderingSchema = z.object({
	order_by: z
		.union([z.literal('created_at'), z.literal('email_address')])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const userEmailAddressFiltersSchema = z.object({
	id: z.string().optional().nullable(),
	email_account_id: z.string().optional().nullable(),
	primary: z.boolean().optional().nullable(),
})

export const listUserEmailAddressParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(userEmailAddressOrderingSchema).optional(),
	filters: userEmailAddressFiltersSchema.optional(),
})

export const listUserFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	query: z.string().optional().nullable(),
	organization_ids: z.array(z.string()).optional().nullable(),
})

export const listUserInviteFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	organization_id: z.string().optional().nullable(),
	statuses: z.array(inviteStatusSchema).optional().nullable(),
	roles: z.array(userRoleSchema).optional().nullable(),
	include_expired: z.boolean().optional(),
})

export const userInviteOrderingSchema = z.object({
	order_by: z
		.union([
			z.literal('created_at'),
			z.literal('email'),
			z.literal('expires_at'),
		])
		.optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const listUserInviteParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(userInviteOrderingSchema).optional(),
	filters: listUserInviteFiltersSchema.optional(),
})

export const userOrderingSchema = z.object({
	order_by: z.union([z.literal('created_at'), z.literal('name')]).optional(),
	order_direction: z.union([z.literal('asc'), z.literal('desc')]).optional(),
})

export const listUserParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(userOrderingSchema).optional(),
	filters: listUserFiltersSchema.optional(),
	include_metrics: z.boolean().optional().nullable(),
	include_organization_roles: z.boolean().optional().nullable(),
})

export const timezoneEnumSchema = z.nativeEnum(TimezoneEnum)

export const matchTypeV2Schema = z.nativeEnum(MatchTypeV2)

export const miscellaneousParametersSchema = z.object({
	name: z.string().optional().nullable(),
	profile_keywords: z.array(z.string()).optional().nullable(),
	total_years: numericQuerySchema.optional().nullable(),
	spoken_languages: z.array(z.string()).optional().nullable(),
	flags: z.array(candidateFlagEnumWithNegationSchema).optional().nullable(),
})

export const mixrankPersonSocialUrlLookupRequestSchema = z.object({
	linkedin_url: z.string(),
})

export const notificationChannelSchema = z.nativeEnum(NotificationChannel)

export const notificationStatusSchema = z.nativeEnum(NotificationStatus)

export const notificationTemplateSchema = z.object({
	id: z.string(),
	type: templateTypeSchema,
	name: z.string(),
	version: z.number(),
	is_active: z.boolean(),
	created_at: z.string(),
	code: notificationTemplateCodeSchema.nullable(),
	from_: z.string().nullable(),
	subject: z.string().nullable(),
	html_content: z.string().nullable(),
	text_content: z.string().nullable(),
	push_content: z.object({}).passthrough().nullable(),
	tags: z.array(z.string()).nullable(),
	template_schema: z.object({}).passthrough().nullable(),
	provider_template_id: z.string().nullable(),
	deleted_at: z.string().nullable(),
})

export const nubelaPersonalEmailRequestSchema = z.object({
	linkedin_url: z.string(),
})

export const nullableGenderSchema = z.object({
	gen_f: z.number().optional().nullable(),
	gen_m: z.number().optional().nullable(),
})

export const nullableEthnicitySchema = z.object({
	eth_asi: z.number().optional().nullable(),
	eth_bla: z.number().optional().nullable(),
	eth_his: z.number().optional().nullable(),
	eth_oth: z.number().optional().nullable(),
	eth_whi: z.number().optional().nullable(),
})

export const nylasAttachmentSchema = z.object({
	id: z.string(),
	grant_id: z.string().optional().nullable(),
	filename: z.string().optional().nullable(),
	content_type: z.string().optional().nullable(),
	size: z.number().optional().nullable(),
	content_id: z.string().optional().nullable(),
	content_disposition: z.string().optional().nullable(),
	is_inline: z.boolean().optional().nullable(),
})

export const nylasCreateAttachmentRequestSchema = z.object({
	filename: z.string(),
	content_type: z.string(),
	content: z.string(),
	size: z.number(),
	content_id: z.string().optional().nullable(),
	content_disposition: z.string().optional().nullable(),
	is_inline: z.boolean().optional().nullable(),
})

export const nylasTrackingOptionsSchema = z.object({
	label: z.string(),
	links: z.boolean(),
	opens: z.boolean(),
	thread_replies: z.boolean(),
})

export const nylasCustomHeaderSchema = z.object({
	name: z.string(),
	value: z.string(),
})

export const nylasDraftSchema = z.object({
	grant_id: z.string(),
	from_: z.array(nylasEmailNameSchema),
	object: z.string().optional(),
	id: z.string().optional().nullable(),
	body: z.string().optional().nullable(),
	thread_id: z.string().optional().nullable(),
	subject: z.string().optional().nullable(),
	snippet: z.string().optional().nullable(),
	to: z.array(nylasEmailNameSchema).optional().nullable(),
	bcc: z.array(nylasEmailNameSchema).optional().nullable(),
	cc: z.array(nylasEmailNameSchema).optional().nullable(),
	reply_to: z.array(nylasEmailNameSchema).optional().nullable(),
	attachments: z.array(nylasAttachmentSchema).optional().nullable(),
	folders: z.array(z.string()).optional().nullable(),
	headers: z.array(nylasMessageHeaderSchema).optional().nullable(),
	unread: z.boolean().optional().nullable(),
	starred: z.boolean().optional().nullable(),
	created_at: z.number().optional().nullable(),
	date: z.number().optional().nullable(),
})

export const nylasGrantSchema = z.object({
	id: z.string(),
	provider: z.string(),
	scope: z.array(z.string()),
	grant_status: z.string().nullable(),
	email: z.string().nullable(),
	user_agent: z.string().nullable(),
	ip: z.string().nullable(),
	state: z.string().nullable(),
	created_at: z.number().nullable(),
	updated_at: z.number().nullable(),
	provider_user_id: z.string().nullable(),
	settings: z.object({}).passthrough().nullable(),
})

export const nylasMessageSchema = z.object({
	grant_id: z.string(),
	from_: z.array(nylasEmailNameSchema),
	object: z.string().optional(),
	id: z.string().optional().nullable(),
	body: z.string().optional().nullable(),
	thread_id: z.string().optional().nullable(),
	subject: z.string().optional().nullable(),
	snippet: z.string().optional().nullable(),
	to: z.array(nylasEmailNameSchema).optional().nullable(),
	bcc: z.array(nylasEmailNameSchema).optional().nullable(),
	cc: z.array(nylasEmailNameSchema).optional().nullable(),
	reply_to: z.array(nylasEmailNameSchema).optional().nullable(),
	attachments: z.array(nylasAttachmentSchema).optional().nullable(),
	folders: z.array(z.string()).optional().nullable(),
	headers: z.array(nylasMessageHeaderSchema).optional().nullable(),
	unread: z.boolean().optional().nullable(),
	starred: z.boolean().optional().nullable(),
	created_at: z.number().optional().nullable(),
	date: z.number().optional().nullable(),
})

export const nylasStoreInboxInputSchema = z.object({
	limit: z.number().optional().nullable(),
	page_token: z.string().optional().nullable(),
	subject: z.string().optional().nullable(),
	any_email: z.string().optional().nullable(),
	from_: z.string().optional().nullable(),
	to: z.string().optional().nullable(),
	cc: z.string().optional().nullable(),
	in_: z.string().optional().nullable(),
	unread: z.boolean().optional().nullable(),
	starred: z.boolean().optional().nullable(),
	thread_id: z.string().optional().nullable(),
	received_before: z.number().optional().nullable(),
	received_after: z.number().optional().nullable(),
	has_attachment: z.boolean().optional().nullable(),
	fields: z
		.union([z.literal('standard'), z.literal('include_headers')])
		.optional()
		.nullable(),
	search_query_native: z.string().optional().nullable(),
	grant_id: z.string(),
})

export const nylasThreadSchema = z.object({
	id: z.string(),
	grant_id: z.string(),
	has_drafts: z.boolean(),
	starred: z.boolean(),
	unread: z.boolean(),
	earliest_message_date: z.number(),
	message_ids: z.array(z.string()),
	folders: z.array(z.string()),
	latest_draft_or_message: z.union([nylasMessageSchema, nylasDraftSchema]),
	object: z.string().optional(),
	latest_message_received_date: z.number().optional().nullable(),
	draft_ids: z.array(z.string()).optional().nullable(),
	snippet: z.string().optional().nullable(),
	subject: z.string().optional().nullable(),
	participants: z.array(nylasEmailNameSchema).optional().nullable(),
	latest_message_sent_date: z.number().optional().nullable(),
	has_attachments: z.boolean().optional().nullable(),
	is_opened: z.boolean().optional().nullable(),
})

export const organizationWithMetricsSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	deleted_at: z.string().optional().nullable(),
	name: z.string(),
	disable_sequence_eligibility: z.boolean(),
	website: z.string().optional().nullable(),
	mailing_address: z.string().optional().nullable(),
	job_count: z.number(),
	active_email_accounts_count: z.number(),
	domain_count: z.number(),
	user_count: z.number(),
})

export const dnsRecordSchema = z.object({
	type: dnsRecordTypeSchema,
	name: z.string(),
	value: z.string(),
	ttl: z.number(),
	priority: z.number().optional().nullable(),
	id: z.string(),
	domain_id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
})

export const domainSchema = z.object({
	id: z.string(),
	domain_name: z.string(),
	status: domainStatusSchema,
	registrar_account_id: z.string().optional().nullable(),
	registrar_domain_id: z.string().optional().nullable(),
	contact_admin: domainContactSchema.optional().nullable(),
	contact_billing: domainContactSchema.optional().nullable(),
	contact_registrant: domainContactSchema.optional().nullable(),
	contact_tech: domainContactSchema.optional().nullable(),
	expiration_protected: z.boolean(),
	transfer_protected: z.boolean(),
	privacy_enabled: z.boolean(),
	locked: z.boolean(),
	auto_renew: z.boolean(),
	expose_whois: z.boolean(),
	name_servers: z.array(z.string()),
	created_at: z.string(),
	updated_at: z.string(),
	deleted_at: z.string().optional().nullable(),
	expires_at: z.string().optional().nullable(),
	renew_deadline: z.string().optional().nullable(),
	transfer_eligible_at: z.string().optional().nullable(),
	registrar_created_at: z.string().optional().nullable(),
	last_synced_at: z.string().optional().nullable(),
	organization_id: z.string().optional().nullable(),
})

export const lisEmailAddressFetchParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(emailAddressFetchOrderingSchema).optional(),
	filters: emailAddressFetchFiltersSchema.optional(),
})

export const paginatedListEmailGroupSchema = z.object({
	entries: z.array(z.union([emailGroupSchema, emailGroupWithMetricsSchema])),
	metadata: listEmailGroupParamsOutputSchema,
})

export const emailMessageSchema = z.object({
	grant_id: z.string(),
	from_: z.array(nylasEmailNameSchema),
	object: z.string().optional(),
	id: z.string().optional().nullable(),
	body: z.string().optional().nullable(),
	thread_id: z.string().optional().nullable(),
	subject: z.string().optional().nullable(),
	snippet: z.string().optional().nullable(),
	to: z.array(nylasEmailNameSchema).optional().nullable(),
	bcc: z.array(nylasEmailNameSchema).optional().nullable(),
	cc: z.array(nylasEmailNameSchema).optional().nullable(),
	reply_to: z.array(nylasEmailNameSchema).optional().nullable(),
	attachments: z
		.array(emailMessageAttachmentMetadataSchema)
		.optional()
		.nullable(),
	folders: z.array(z.string()).optional().nullable(),
	headers: z.array(nylasMessageHeaderSchema).optional().nullable(),
	unread: z.boolean().optional().nullable(),
	starred: z.boolean().optional().nullable(),
	created_at: z.string(),
	date: z.number().optional().nullable(),
	updated_at: z.string(),
	is_bounced: z.boolean(),
	is_failed: z.boolean(),
	is_warmup: z.boolean(),
	schedule_id: z.string().optional().nullable(),
	reply_to_message_id: z.string().optional().nullable(),
	user_email_account_id: z.string().optional().nullable(),
	header_message_id: z.string().optional().nullable(),
})

export const paginatedListEmailThreadsSchema = z.object({
	entries: z.array(emailThreadSchema),
	metadata: listEmailThreadParamsOutputSchema,
})

export const queryParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(orderingSchema).optional(),
	filters: baseModelSchema.optional(),
})

export const notificationSchema = z.object({
	id: z.string(),
	match_id: z.string().nullable(),
	channel: notificationChannelSchema,
	recipients: z.array(z.string()),
	status: z.string(),
	sent_at: z.string().nullable(),
	error: z.string().nullable(),
	provider_message_id: z.string().nullable(),
	notification_template_id: z.string(),
	template_data: z.object({}).passthrough().nullable(),
	delivery_metadata: z.object({}).passthrough().nullable(),
	created_at: z.string(),
})

export const paginatedListNotificationTemplateSchema = z.object({
	entries: z.array(notificationTemplateSchema),
	metadata: listNotificationTemplateParamsOutputSchema,
})

export const paginatedListOrganizationSchema = z.object({
	entries: z.array(
		z.union([organizationSchema, organizationWithMetricsSchema]),
	),
	metadata: listOrganizationParamsOutputSchema,
})

export const registrarAccountSchema = z.object({
	id: z.string(),
	name: z.string(),
	registrar: registrarSchema,
	created_at: z.string(),
	updated_at: z.string(),
	deleted_at: z.string().optional().nullable(),
	last_synced_at: z.string().optional().nullable(),
})

export const registrarAccountWithMetricsSchema = z.object({
	id: z.string(),
	name: z.string(),
	registrar: registrarSchema,
	created_at: z.string(),
	updated_at: z.string(),
	deleted_at: z.string().optional().nullable(),
	last_synced_at: z.string().optional().nullable(),
	domain_count: z.number(),
})

export const sequenceTemplateSchema = z.object({
	id: z.string(),
	name: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	organization_id: z.string(),
	job_id: z.string().optional().nullable(),
	template_steps: z.array(templateStepSchema),
	preview: z.string(),
})

export const userEmailAddressSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	email_account_id: z.string(),
	email_address_id: z.number(),
	primary: z.boolean(),
	email_address: z.string().nullable(),
})

export const userInviteSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	email: z.string(),
	organization_id: z.string(),
	status: inviteStatusSchema,
	role: userRoleSchema,
	created_by: z.string(),
	expires_at: z.string(),
	last_sent_at: z.string(),
	resend_count: z.number(),
	accepted_at: z.string().optional().nullable(),
	accepted_by: z.string().optional().nullable(),
	revoked_at: z.string().optional().nullable(),
	revoked_by: z.string().optional().nullable(),
})

export const nullableDemographicsSchema = z.object({
	gender: nullableGenderSchema.optional().nullable(),
	ethnicity: nullableEthnicitySchema.optional().nullable(),
})

export const personalizedEmailSchema = z.object({
	search_run_id: z.string(),
	candidate_id: z.string(),
	email_subject: z.string(),
	email_body: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
})

export const publicationsParametersInputSchema = z.object({
	topics: z.array(z.string()).optional().nullable(),
	min_num_citations: numericQuerySchema.optional().nullable(),
	min_h_index: numericQuerySchema.optional().nullable(),
	patents_keywords: z.array(z.string()).optional().nullable(),
})

export const publicationsParametersOutputSchema = z.object({
	topics: z.array(z.string()).optional().nullable(),
	min_num_citations: numericQuerySchema.optional().nullable(),
	min_h_index: numericQuerySchema.optional().nullable(),
	patents_keywords: z.array(z.string()).optional().nullable(),
})

export const queryParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(orderingSchema).optional(),
	filters: baseModelSchema.optional(),
})

export const recordClientDecisionRequestSchema = z.object({
	decision: clientDecisionSchema,
	introduction_email: candidateOutreachEmailSchema.optional().nullable(),
	reject_email: candidateOutreachEmailSchema.optional().nullable(),
})

export const requirementCategoryTypeSchema = z.nativeEnum(
	RequirementCategoryType,
)

export const requirementClassificationV2Schema = z.object({
	classification: matchTypeV2Schema,
	reason: z.string(),
	highlights: z.record(
		z.union([
			z.string(),
			z.array(
				z
					.union([z.string(), z.array(z.string().nullable())])
					.nullable(),
			),
		]),
	),
	highlight_texts: z.array(z.string()),
})

export const requirementExampleV2Schema = z.object({
	requirement: z.string(),
	candidate: z.object({}).passthrough().optional().nullable(),
	highlight: z.string().optional().nullable(),
	match: matchTypeV2Schema,
	reason: z.string().optional().nullable(),
	category: z.string().optional().nullable(),
})

export const sequenceDraftSchema = z.object({
	html: z.string(),
	markdown: z.string(),
	subject: z.string(),
	version: z.string().optional(),
	to: z.array(nylasEmailNameSchema),
	bcc: z.array(nylasEmailNameSchema).optional().nullable(),
	cc: z.array(nylasEmailNameSchema).optional().nullable(),
	reply_to: z.array(nylasEmailNameSchema),
	seconds_from_now: z.number().optional(),
	deliver_at: z.string(),
	delivered_at: z.string().optional().nullable(),
})

export const sequenceStatusSchema = z.nativeEnum(SequenceStatus)

export const scheduledSequenceFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	org_ids: z.array(z.string()).optional().nullable(),
	user_ids: z.array(z.string()).optional().nullable(),
	template_ids: z.array(z.string()).optional().nullable(),
	candidate_ids: z.array(z.string()).optional().nullable(),
	job_ids: z.array(z.string()).optional().nullable(),
	statuses: z.array(sequenceStatusSchema).optional().nullable(),
	subject_query: z.string().optional().nullable(),
	thread_ids: z.array(z.string()).optional().nullable(),
	created_on_or_after: z.string().optional().nullable(),
})

export const sequenceAllocationPreviewSchema = z.object({
	accounts: z.record(accountAllocationSchema),
	total_initial_outreach_load: z.number(),
	total_initial_outreach_load_remaining: z.number(),
})

export const requirementSchema = z.object({
	text: z.string(),
	category: requirementCategoryTypeSchema,
})

export const candidateToScoreV2ConfigInputSchema = z.object({
	use_rag: z.boolean().optional().nullable(),
	use_cache: z.boolean().optional().nullable(),
	custom_instructions: z.string().optional().nullable(),
	llm: candidateScoringLLMConfigSchema.optional().nullable(),
})

export const candidateToScoreV2MetadataSchema = z.object({
	examples: z.array(requirementExampleV2Schema).optional().nullable(),
})

export const scoreManualSearchRequestSchema = z.object({
	metadata_key: z.string().optional().nullable(),
	metadata_value: z.string().optional().nullable(),
})

export const scoreManualSearchSetupSchema = z.object({
	search_id: z.string(),
	job_summary_id: z.string(),
	limit: z.number().optional(),
	score_config: candidateToScoreV2ConfigInputSchema.optional().nullable(),
})

export const scoreManualSearchSetupV2Schema = z.object({
	job_summary_id: z.string(),
	count: z.number(),
	offset: z.number(),
	score_config: candidateToScoreV2ConfigInputSchema.optional().nullable(),
})

export const searchCandidatesParamsSchema = z.object({
	linkedin_url: z.string().optional().nullable(),
})

export const locationParametersSchema = z.object({
	cities: z.array(z.string()).optional().nullable(),
	states: z.array(z.string()).optional().nullable(),
	distance_from_cities: z.number().optional().nullable(),
	timezone: timezoneEnumSchema.optional().nullable(),
	us_only: z.boolean().optional().nullable(),
})

export const personModelBackwardsCompatibleSchema = z.object({
	mhid: z.string(),
	build_id: z.number().optional().nullable(),
	internal_primary_key: z.string().optional().nullable(),
	update_metadata: nullableUpdateMetaSchema.optional().nullable(),
	raw_ids: z.array(z.string().nullable()).optional().nullable(),
	mixrank_slug_status: z.string().optional().nullable(),
	linkedin_username: z.string().optional().nullable(),
	linkedin_usernames: z.array(z.string().nullable()).optional().nullable(),
	linkedin_id: z.number().optional().nullable(),
	linkedin_ids: z.array(z.number().nullable()).optional().nullable(),
	pdl_id: z.string().optional().nullable(),
	pdl_ids: z.array(z.string().nullable()).optional().nullable(),
	job_title: z.string().optional().nullable(),
	name: z.string().optional().nullable(),
	names: z.array(z.string().nullable()).optional().nullable(),
	first_name: z.string().optional().nullable(),
	last_name: z.string().optional().nullable(),
	industry: z.string().optional().nullable(),
	country: z.string().optional().nullable(),
	country_code: z.string().optional().nullable(),
	locality: z.string().optional().nullable(),
	location_geo_coordinate: z.string().optional().nullable(),
	pdl_metro: z.string().optional().nullable(),
	skills: z.array(z.string().nullable()).optional().nullable(),
	linkedin_follower_count: z.number().optional().nullable(),
	linkedin_connections_count: z.number().optional().nullable(),
	github_username: z.string().optional().nullable(),
	github_usernames: z.array(z.string().nullable()).optional().nullable(),
	email: z.string().optional().nullable(),
	emails: z.array(z.string().nullable()).optional().nullable(),
	user_website: z.string().optional().nullable(),
	user_websites: z.array(z.string().nullable()).optional().nullable(),
	github_followers: z.number().optional().nullable(),
	github_following: z.number().optional().nullable(),
	github_repositories_count: z.number().optional().nullable(),
	github_repositories_own_count: z.number().optional().nullable(),
	github_repositories_forked_count: z.number().optional().nullable(),
	github_repositories_own: z
		.array(z.string().nullable())
		.optional()
		.nullable(),
	github_repositories_forked: z
		.array(z.string().nullable())
		.optional()
		.nullable(),
	github_contributions_made_last_year: z.number().optional().nullable(),
	github_bio: z.string().optional().nullable(),
	github_achievements: z.array(z.string().nullable()).optional().nullable(),
	gscholar_id: z.string().optional().nullable(),
	gscholar_ids: z.array(z.string().nullable()).optional().nullable(),
	gscholar_avatar_url: z.string().optional().nullable(),
	gscholar_title: z.string().optional().nullable(),
	gscholar_labels: z.array(z.string().nullable()).optional().nullable(),
	gscholar_citations_total: z.number().optional().nullable(),
	gscholar_citations_per_year: z
		.array(nullableCitationsForYearSchema.nullable())
		.optional()
		.nullable(),
	gscholar_h_index: z.number().optional().nullable(),
	gscholar_i10_index: z.number().optional().nullable(),
	gscholar_articles: z
		.array(nullableGScholarArticleSchema.nullable())
		.optional()
		.nullable(),
	twitter_username: z.string().optional().nullable(),
	twitter_usernames: z.array(z.string().nullable()).optional().nullable(),
	twitter_followers_count: z.number().optional().nullable(),
	mixrank_id: z.string().optional().nullable(),
	mixrank_ids: z.array(z.string().nullable()).optional().nullable(),
	url: z.string().optional().nullable(),
	linkedin_url: z.string().optional().nullable(),
	twitter_url: z.string().optional().nullable(),
	github_url: z.string().optional().nullable(),
	gscholar_citations_url: z.string().optional().nullable(),
	city: z.string().optional().nullable(),
	state: z.string().optional().nullable(),
	description: z.string().optional().nullable(),
	headline: z.string().optional().nullable(),
	profile_pic: z.string().optional().nullable(),
	updated_at: z.string().optional().nullable(),
	pdl_job_last_verified: z.string().optional().nullable(),
	education: z
		.array(nullableEducationSchema.nullable())
		.optional()
		.nullable(),
	experience: z
		.array(nullableExperienceSchema.nullable())
		.optional()
		.nullable(),
	years_of_experience: z.number().optional().nullable(),
	is_employed: z.boolean().optional().nullable(),
	role: z.array(nullableRoleHistorySchema.nullable()).optional().nullable(),
	company: z
		.array(nullableCompanyHistorySchema.nullable())
		.optional()
		.nullable(),
	current_company: z.string().optional().nullable(),
	all_schools: z.array(z.string().nullable()).optional().nullable(),
	courses: z.array(nullableCourseSchema.nullable()).optional().nullable(),
	certifications: z
		.array(nullableCertificationSchema.nullable())
		.optional()
		.nullable(),
	languages: z.array(nullableLanguageSchema.nullable()).optional().nullable(),
	recommendations: z
		.array(nullableRecommendationSchema.nullable())
		.optional()
		.nullable(),
	articles: z.array(nullableArticleSchema.nullable()).optional().nullable(),
	awards: z.array(nullableAwardSchema.nullable()).optional().nullable(),
	patents: z.array(nullablePatentSchema.nullable()).optional().nullable(),
	projects: z.array(nullableProjectSchema.nullable()).optional().nullable(),
	volunteering: z
		.array(nullableVolunteeringSchema.nullable())
		.optional()
		.nullable(),
	publications: z
		.array(nullablePublicationSchema.nullable())
		.optional()
		.nullable(),
	stats: nullableStatsSchema.optional().nullable(),
	version_id: z.number().optional().nullable(),
	demographics: nullableDemographicsSchema.optional().nullable(),
	is_veteran: z.number().optional().nullable(),
	has_security_clearance: z.number().optional().nullable(),
	profile_completeness_score: z.number().optional().nullable(),
	education_prestige: z.number().optional().nullable(),
	candidate_flags: z.array(z.string().nullable()).optional().nullable(),
})

export const searchRunStatusSchema = z.nativeEnum(SearchRunStatus)

export const searchRunJobInformationSchema = z.object({
	job_description: z.string().optional().nullable(),
	recruiter_notes: z.string().optional().nullable(),
	client_intake_notes: z.string().optional().nullable(),
})

export const highlightedJobSummarySchema = z.object({
	must_have: z
		.array(highlightedJobSummaryRequirementSchema)
		.optional()
		.nullable(),
	nice_to_have: z
		.array(highlightedJobSummaryRequirementSchema)
		.optional()
		.nullable(),
})

export const searchRunOutreachSettingsSchema = z.object({
	auto_shortlist: z.boolean(),
	auto_engagement: z.boolean(),
	target_interest_weekly: z.number(),
	calendly_link: z.string().nullable(),
	custom_email_template: z.string(),
	email_template_subject: z.string().optional(),
})

export const searchRunScoreWorkflowStateSchema = z.nativeEnum(
	SearchRunScoreWorkflowState,
)

export const searchScoreMetricsSchema = z.object({
	must_have: z.array(z.record(z.number())),
	nice_have: z.array(z.record(z.number())),
	total: z.record(z.number()),
})

export const searchScoreWorkflowStateSchema = z.nativeEnum(
	SearchScoreWorkflowState,
)

export const searchScoreWorkflowOutputSchema = z.object({
	state: searchScoreWorkflowStateSchema.optional(),
	job_summary_id: z.string(),
	limit: z.number().optional(),
	workflow_run_id: z.string().optional().nullable(),
	config: candidateToScoreV2ConfigOutputSchema.optional().nullable(),
	metrics: searchScoreMetricsSchema.optional().nullable(),
})

export const shortlistOrRejectCandidatesRequestSchema = z.object({
	job_id: z.string(),
	search_id: z.string().optional().nullable(),
	candidate_ids: z.array(z.string()).optional().nullable(),
	external_candidate_ids: z.array(z.string()).optional().nullable(),
})

export const signalHireRequestSchema = z.object({
	item: z.string(),
})

export const signalHireResultSchema = z.object({
	item: z.string(),
	status: z.union([
		z.literal('success'),
		z.literal('failed'),
		z.literal('credits_are_over'),
	]),
	candidate: z.object({}).passthrough().optional().nullable(),
})

export const streamEventSchema = z.object({
	event: z.string(),
	data: z.object({}).passthrough().optional().nullable(),
})

export const topLevelParametersInputSchema = z.object({
	titles: z.array(z.string()).optional().nullable(),
	companies: z.array(z.string()).optional().nullable(),
	keywords: z.array(z.string()).optional().nullable(),
	total_years: numericQuerySchema.optional().nullable(),
	location: locationParametersSchema.optional().nullable(),
})

export const topLevelParametersOutputSchema = z.object({
	titles: z.array(z.string()).optional().nullable(),
	companies: z.array(z.string()).optional().nullable(),
	keywords: z.array(z.string()).optional().nullable(),
	total_years: numericQuerySchema.optional().nullable(),
	location: locationParametersSchema.optional().nullable(),
})

export const updateCandidateEmailAddressItemParamsSchema = z.object({
	status: candidateEmailAddressStatusSchema.optional().nullable(),
	status_update_message_id: z.string().optional().nullable(),
	label: candidateEmailAddressLabelSchema.optional().nullable(),
	update_primary: z.boolean().optional().nullable(),
})

export const updateCandidateEmailAddressRequestSchema = z.object({
	candidate_id: z.string(),
	email_address: z.string(),
	params: updateCandidateEmailAddressItemParamsSchema,
})

export const updateCandidateParamsSchema = z.object({
	owner_id: z.string().optional().nullable(),
	external_id: z.string().optional().nullable(),
	first_name: z.string().optional().nullable(),
	last_name: z.string().optional().nullable(),
	job_title: z.string().optional().nullable(),
	current_company: z.string().optional().nullable(),
	locality: z.string().optional().nullable(),
	phone_number: z.string().optional().nullable(),
	candidate_tags: z.array(z.string()).optional().nullable(),
	search_criteria: z.record(z.array(z.string())).optional().nullable(),
	notes: z.string().optional().nullable(),
	min_comp_expectations: z.number().optional().nullable(),
	max_comp_expectations: z.number().optional().nullable(),
	equity_expectations: z.number().optional().nullable(),
	last_fetched_time: z.string().optional().nullable(),
	email_address_fetch_status: emailAddressFetchStatusSchema
		.optional()
		.nullable(),
	search_metadata: z.string().optional().nullable(),
})

export const updateJobParamsSchema = z.object({
	organization_id: z.string().optional().nullable(),
	name: z.string().optional().nullable(),
	owner_id: z.string().optional().nullable(),
	job_title: z.string().optional().nullable(),
	company_name: z.string().optional().nullable(),
	status: z
		.union([
			z.literal('Active'),
			z.literal('Passive'),
			z.literal('Paused'),
			z.literal('Won'),
			z.literal('Lost'),
		])
		.optional()
		.nullable(),
	status_reason: z.string().optional().nullable(),
	job_description: z.string().optional().nullable(),
	client_intake_notes: z.string().optional().nullable(),
	recruiter_notes: z.string().optional().nullable(),
	links: z.record(z.string()).optional().nullable(),
	job_summary_id: z.string().optional().nullable(),
	score_custom_instructions: z.string().optional().nullable(),
	hiring_manager_name: z.string().optional().nullable(),
	notify_candidate_review: z.array(z.string()).optional().nullable(),
	notify_introduction: z.array(z.string()).optional().nullable(),
	notify_weekly_update: z.array(z.string()).optional().nullable(),
	notify_weekly_update_template_id: z.string().optional().nullable(),
	email_group_id: z.string().optional().nullable(),
	weekly_outreach_quota: z.number().optional().nullable(),
})

export const updateNotificationTemplateSchema = z.object({
	from_: z.string().optional().nullable(),
	subject: z.string().optional().nullable(),
	html_content: z.string().optional().nullable(),
	text_content: z.string().optional().nullable(),
	push_content: z.object({}).passthrough().optional().nullable(),
	tags: z.array(z.string()).optional().nullable(),
	template_schema: z.object({}).passthrough().optional().nullable(),
	provider_template_id: z.string().optional().nullable(),
})

export const updateOrganizationParamsSchema = z.object({
	name: z.string().optional().nullable(),
	disable_sequence_eligibility: z.boolean().optional().nullable(),
	website: z.string().optional().nullable(),
	mailing_address: z.string().optional().nullable(),
})

export const updatePersonalizedEmailSchema = z.object({
	email_subject: z.string(),
	email_body: z.string(),
})

export const updateRegistrarAccountParamsSchema = z.object({
	name: z.string().optional().nullable(),
	credentials: z
		.union([
			z
				.object({
					type: z.literal('GODADDY').optional(),
				})
				.and(goDaddyCredentialsSchema),
			z
				.object({
					type: z.literal('ROUTE53').optional(),
				})
				.and(route53CredentialsSchema),
		])
		.optional()
		.nullable(),
})

export const updateSearchParamsSchema = z.object({
	total_count: z.number().optional().nullable(),
	total_count_limit: z
		.union([z.literal('eq'), z.literal('gte')])
		.optional()
		.nullable(),
	name: z.string().optional().nullable(),
	starred: z.boolean().optional().nullable(),
})

export const updateSequenceStepParamsSchema = z.object({
	step: z.number(),
	markdown: z.string(),
})

export const updateTemplateSchema = z.object({
	name: z.string(),
	template_steps: z.array(templateStepSchema),
	organization_id: z.string(),
	job_id: z.string().optional().nullable(),
})

export const updateUserEmailAccountParamsSchema = z.object({
	inbox_warm: z.boolean().optional().nullable(),
	daily_limit: z.number().optional().nullable(),
	warmup_filter_tags: z.array(z.string()).optional().nullable(),
	first_name: z.string().optional().nullable(),
	last_name: z.string().optional().nullable(),
	password: z.string().optional().nullable(),
	account_type: emailAccountTypeSchema.optional().nullable(),
})

export const updateUserParamsSchema = z.object({
	name: z.string().optional().nullable(),
	picture: z.string().optional().nullable(),
	email: z.string().optional().nullable(),
	nylas_grant_id: z.string().optional().nullable(),
})

export const userOrganizationRoleSchema = z.object({
	organization_id: z.string(),
	role: userRoleSchema,
})

export const userConfidentialSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	deleted_at: z.string().optional().nullable(),
	email: z.string(),
	name: z.string(),
	picture: z.string().optional().nullable(),
	primary_organization_id: z.string().optional().nullable(),
	is_admin: z.boolean().optional(),
	organization_roles: z.array(userOrganizationRoleSchema).optional(),
	auth_id: z.string(),
	nylas_access_token: z.string().optional().nullable(),
	nylas_grant_id: z.string().optional().nullable(),
})

export const warmyMailboxSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	email: z.string(),
	from_name: z.string().nullable(),
	warmup_active: z.boolean(),
	status: z.string(),
	deliverability: z.number(),
	sent_today: z.number(),
	received_today: z.number(),
	dns_score: z.number(),
	isp_score: z.number(),
	temperature: z.number(),
	warmup_language_code: z.string(),
	warmup_language_name: z.string(),
	warmup_topic_id: z.number(),
	warmup_topic_title: z.string(),
	warmy_mailbox_id: z.number(),
	user_email_account_id: z.string().nullable(),
})

export const userSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	deleted_at: z.string().optional().nullable(),
	email: z.string(),
	name: z.string(),
	picture: z.string().optional().nullable(),
	primary_organization_id: z.string().optional().nullable(),
	is_admin: z.boolean().optional(),
	organization_roles: z
		.array(userOrganizationRoleSchema)
		.optional()
		.nullable(),
})

export const userWithMetricsSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	deleted_at: z.string().optional().nullable(),
	email: z.string(),
	name: z.string(),
	picture: z.string().optional().nullable(),
	primary_organization_id: z.string().optional().nullable(),
	is_admin: z.boolean().optional(),
	organization_roles: z
		.array(userOrganizationRoleSchema)
		.optional()
		.nullable(),
	job_count: z.number(),
	candidate_count: z.number(),
})

export const workAndPublicationsCollapseInputSchema = z.object({
	publications: publicationsParametersInputSchema.optional().nullable(),
	github: githubParametersInputSchema.optional().nullable(),
})

export const workAndPublicationsCollapseOutputSchema = z.object({
	publications: publicationsParametersOutputSchema.optional().nullable(),
	github: githubParametersOutputSchema.optional().nullable(),
})

export const listOrganizationParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(organizationOrderingSchema).optional(),
	filters: listOrganizationFiltersSchema.optional(),
	include_metrics: z.boolean().optional().nullable(),
})

export const createOrganizationDataSchema = z.object({
	body: createOrganizationRequestSchema,
})

export const deleteOrganizationDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		org_id: z.string(),
	}),
	query: z.never().optional(),
})

export const getOrganizationByIdDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		org_id: z.string(),
	}),
	query: z
		.object({
			include_metrics: z.boolean().optional().nullable(),
		})
		.optional(),
})

export const updateOrganizationDataSchema = z.object({
	body: updateOrganizationParamsSchema,
	path: z.object({
		org_id: z.string(),
	}),
	query: z.never().optional(),
})

export const getOrganizationDefaultEmailGroupDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		org_id: z.string(),
	}),
	query: z.never().optional(),
})

export const listJobsDataSchema = z.object({
	body: listJobParamsInputSchema,
})

export const createJobDataSchema = z.object({
	body: createJobRequestSchema,
})

export const deleteJobDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		job_id: z.string(),
	}),
	query: z.never().optional(),
})

export const getJobDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		job_id: z.string(),
	}),
	query: z
		.object({
			include_weekly_quota: z.boolean().optional().nullable(),
		})
		.optional(),
})

export const updateJobDataSchema = z.object({
	body: updateJobParamsSchema,
	path: z.object({
		job_id: z.string(),
	}),
	query: z.never().optional(),
})

export const listJobSummariesDataSchema = z.object({
	body: queryParamsInputSchema,
	path: z.object({
		job_id: z.string(),
	}),
	query: z.never().optional(),
})

export const createJobSummaryForProjectDataSchema = z.object({
	body: createJobSummaryRequestSchema,
	path: z.object({
		job_id: z.string(),
	}),
	query: z.never().optional(),
})

export const getJobSummaryDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		job_id: z.string(),
		job_summary_id: z.string(),
	}),
	query: z.never().optional(),
})

export const addTeamMemberToJobDataSchema = z.object({
	body: addTeamMemberRequestSchema,
	path: z.object({
		job_id: z.string(),
	}),
	query: z.never().optional(),
})

export const removeTeamMemberFromJobDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		job_id: z.string(),
		user_id: z.string(),
	}),
	query: z.never().optional(),
})

export const transferJobOrganizationDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		new_org_id: z.string(),
		job_id: z.string(),
	}),
	query: z.never().optional(),
})

export const listUserParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(userOrderingSchema).optional(),
	filters: listUserFiltersSchema.optional(),
	include_metrics: z.boolean().optional().nullable(),
	include_organization_roles: z.boolean().optional().nullable(),
})

export const createUserDataSchema = z.object({
	body: createUserRequestSchema,
})

export const updateUserSelfDataSchema = z.object({
	body: updateUserParamsSchema,
})

export const getUserByIdDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		id: z.string(),
	}),
	query: z
		.object({
			include_metrics: z.boolean().optional().nullable(),
		})
		.optional(),
})

export const deleteUserDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		user_id: z.string(),
	}),
	query: z.never().optional(),
})

export const removeUserFromOrganizationDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		user_id: z.string(),
		org_id: z.string(),
	}),
	query: z.never().optional(),
})

export const createUserInviteRequestSchema = z.object({
	email: z.string(),
	organization_id: z.string(),
	role: userRoleSchema,
})

export const getUserInviteDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		invite_id: z.string(),
	}),
	query: z.never().optional(),
})

export const listUserInviteParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(userInviteOrderingSchema).optional(),
	filters: listUserInviteFiltersSchema.optional(),
})

export const resendUserInviteDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		invite_id: z.string(),
	}),
	query: z.never().optional(),
})

export const revokeUserInviteDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		invite_id: z.string(),
	}),
	query: z.never().optional(),
})

export const listCandidatesDataSchema = z.object({
	body: listCandidateParamsInputSchema,
})

export const createCandidateDataSchema = z.object({
	body: createCandidateRequestSchema,
})

export const deleteCandidateDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		candidate_id: z.string(),
	}),
	query: z.never().optional(),
})

export const getCandidateDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		candidate_id: z.string(),
	}),
	query: z.never().optional(),
})

export const updateCandidateDataSchema = z.object({
	body: updateCandidateParamsSchema,
	path: z.object({
		candidate_id: z.string(),
	}),
	query: z.never().optional(),
})

export const getCandidateFromExternalIdDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		external_id: z.string(),
	}),
	query: z.never().optional(),
})

export const searchCandidatesDataSchema = z.object({
	body: searchCandidatesParamsSchema,
})

export const listSearchParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(searchOrderingSchema).optional(),
	filters: listSearchFiltersSchema.optional(),
})

export const listParentSearchesDataSchema = z.object({
	body: listSearchFiltersSchema,
})

export const buildFiltersRequestSchema = z.object({
	job_summary: jobSummarySchema,
	prune_filters: z.boolean().optional().nullable(),
})

export const fESearchFiltersInputSchema = z.object({
	top_level: topLevelParametersInputSchema.optional().nullable(),
	education: z.array(educationParametersInputSchema).optional().nullable(),
	experience: z.array(experienceParametersInputSchema).optional().nullable(),
	work_and_publications: workAndPublicationsCollapseInputSchema
		.optional()
		.nullable(),
	candidate_details: candidateDetailsCollapseSchema.optional().nullable(),
})

export const deleteSearchDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		search_id: z.string(),
	}),
	query: z.never().optional(),
})

export const getSearchDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		search_id: z.string(),
	}),
	query: z.never().optional(),
})

export const updateSearchDataSchema = z.object({
	body: updateSearchParamsSchema,
	path: z.object({
		search_id: z.string(),
	}),
	query: z.never().optional(),
})

export const initiateIteratorForSearchDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		search_id: z.string(),
	}),
	query: z.never().optional(),
})

export const listSearchScoresDataSchema = z.object({
	body: listCandidateScoreParamsSchema,
	path: z.object({
		search_id: z.string(),
	}),
	query: z.never().optional(),
})

export const setupScoreManualSearchDataSchema = z.object({
	body: scoreManualSearchSetupSchema,
	path: z.object({
		search_id: z.string(),
	}),
	query: z.never().optional(),
})

export const setupScoreManualSearchV2DataSchema = z.object({
	body: scoreManualSearchSetupV2Schema,
	path: z.object({
		search_id: z.string(),
	}),
	query: z.never().optional(),
})

export const resetScoreManualSearchDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		search_id: z.string(),
	}),
	query: z.never().optional(),
})

export const startScoreManualSearchDataSchema = z.object({
	body: scoreManualSearchRequestSchema,
	path: z.object({
		search_id: z.string(),
	}),
	query: z.never().optional(),
})

export const startScoreManualSearchV2DataSchema = z.object({
	body: scoreManualSearchRequestSchema,
	path: z.object({
		search_id: z.string(),
	}),
	query: z.never().optional(),
})

export const listSearchRunParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(searchRunOrderingSchema).optional(),
	filters: listSearchRunFiltersSchema.optional(),
})

export const createSearchRunDataSchema = z.object({
	body: createSearchRunRequestSchema,
})

export const getSearchRunDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		search_run_id: z.string(),
	}),
	query: z.never().optional(),
})

export const updateSearchRunParamsSchema = z.object({
	job_information: searchRunJobInformationSchema.optional().nullable(),
	job_summary: highlightedJobSummarySchema.optional().nullable(),
	search_filters: fESearchFiltersInputSchema.optional().nullable(),
})

export const initiateIteratorForSearchRunDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		search_run_id: z.string(),
	}),
	query: z.never().optional(),
})

export const deletePersonalizedEmailDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		search_run_id: z.string(),
		candidate_id: z.string(),
	}),
	query: z.never().optional(),
})

export const getPersonalizedEmailDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		search_run_id: z.string(),
		candidate_id: z.string(),
	}),
	query: z.never().optional(),
})

export const createPersonalizedEmailDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		search_run_id: z.string(),
		candidate_id: z.string(),
	}),
	query: z.never().optional(),
})

export const updatePersonalizedEmailDataSchema = z.object({
	body: updatePersonalizedEmailSchema,
	path: z.object({
		search_run_id: z.string(),
		candidate_id: z.string(),
	}),
	query: z.never().optional(),
})

export const listSearchRunScoresDataSchema = z.object({
	body: listCandidateScoreParamsSchema,
	path: z.object({
		search_run_id: z.string(),
	}),
	query: z.never().optional(),
})

export const updateSearchRunOutreachSettingsDataSchema = z.object({
	body: searchRunOutreachSettingsSchema,
	path: z.object({
		search_run_id: z.string(),
	}),
	query: z.never().optional(),
})

export const resetScoreSearchRunDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		search_run_id: z.string(),
	}),
	query: z.never().optional(),
})

export const candidateSearchRequestSchema = z.object({
	limit: z.number().optional(),
	offset: z.number().optional(),
	source_includes: z.array(z.string()).optional().nullable(),
	excluded_candidate_ids: z.array(z.string()).optional().nullable(),
	with_highlights: z.boolean().optional(),
	search_filters: fESearchFiltersInputSchema,
	search_id: z.string().optional().nullable(),
})

export const searchCandidatesByExternalIdDataSchema = z.object({
	body: z.array(z.string()),
	path: z.object({
		job_id: z.string(),
	}),
	query: z.never().optional(),
})

export const searchCandidateExcludeRejectedQueryDataSchema = z.object({
	body: candidateSearchRequestSchema,
	path: z.object({
		job_id: z.string(),
	}),
	query: z.never().optional(),
})

export const scoreCandidateRequestSchema = z.object({
	candidate_id: z.string(),
	must_requirements: z.array(requirementSchema).optional().nullable(),
	nice_requirements: z.array(requirementSchema).optional().nullable(),
	config: candidateToScoreV2ConfigInputSchema.optional().nullable(),
	metadata: candidateToScoreV2MetadataSchema.optional().nullable(),
})

export const streamCandidateScoringStreamScoringJobIdGetDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		scoring_job_id: z.string(),
	}),
	query: z
		.object({
			hatchet_key: z.string().optional().nullable(),
		})
		.optional(),
})

export const createScheduledSequenceRawRequestSchema = z.object({
	candidate_id: z.string(),
	job_id: z.string(),
	template_steps: z.array(templateStepSchema),
})

export const createScheduledSequenceFromTemplateDataSchema = z.object({
	body: createScheduledSequenceFromTemplateRequestSchema,
})

export const bulkCreateScheduledSequenceFromTemplateDataSchema = z.object({
	body: z.array(createScheduledSequenceFromTemplateRequestSchema),
})

export const getScheduledSequenceDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		sequence_id: z.string(),
	}),
	query: z
		.object({
			include_template: z.boolean().optional().nullable(),
		})
		.optional(),
})

export const updateScheduledSequenceDataSchema = z.object({
	body: z.array(updateSequenceStepParamsSchema),
	path: z.object({
		sequence_id: z.string(),
	}),
	query: z.never().optional(),
})

export const listScheduledSequenceParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(scheduledSequenceOrderingSchema).optional(),
	filters: scheduledSequenceFiltersSchema.optional(),
	include_template: z.boolean().optional().nullable(),
})

export const bulkCancelScheduledSequencesDataSchema = z.object({
	body: z.array(z.string()),
})

export const sequenceWorkflowHealthcheckDataSchema = z.object({
	body: z.never().optional(),
	path: z.never().optional(),
	query: z.object({
		days: z.number(),
	}),
})

export const getDefaultSenderDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		job_id: z.string(),
	}),
	query: z.never().optional(),
})

export const queryLoadBalancerDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		job_id: z.string(),
	}),
	query: z.never().optional(),
})

export const getJobSchedulingPreviewDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		job_id: z.string(),
	}),
	query: z.never().optional(),
})

export const getJobMetricsDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		job_id: z.string(),
	}),
	query: z
		.object({
			window_start_date: z.string().optional().nullable(),
		})
		.optional(),
})

export const createSequenceTemplateDataSchema = z.object({
	body: createTemplateRequestSchema,
})

export const deleteSequenceTemplateDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		template_id: z.string(),
	}),
	query: z.never().optional(),
})

export const getSequenceTemplateDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		template_id: z.string(),
	}),
	query: z.never().optional(),
})

export const updateSequenceTemplateDataSchema = z.object({
	body: updateTemplateSchema,
	path: z.object({
		template_id: z.string(),
	}),
	query: z.never().optional(),
})

export const listSequenceTemplateParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(sequenceTemplateOrderingSchema).optional(),
	filters: sequenceTemplateFiltersSchema.optional(),
})

export const oauthExchangeDataSchema = z.object({
	body: z.never().optional(),
	path: z.never().optional(),
	query: z.object({
		code: z.string(),
	}),
})

export const nylasCreateDraftRequestSchema = z.object({
	body: z.string(),
	subject: z.string(),
	to: z.array(nylasEmailNameSchema),
	bcc: z.array(nylasEmailNameSchema).optional().nullable(),
	cc: z.array(nylasEmailNameSchema).optional().nullable(),
	reply_to: z.array(nylasEmailNameSchema),
	attachments: z.array(nylasCreateAttachmentRequestSchema).optional(),
	starred: z.boolean().optional().nullable(),
	send_at: z.number().optional().nullable(),
	reply_to_message_id: z.string().optional().nullable(),
	tracking_options: nylasTrackingOptionsSchema.optional(),
	custom_headers: z.array(nylasCustomHeaderSchema).optional().nullable(),
})

export const readThreadsDataSchema = z.object({
	body: z.never().optional(),
	path: z.never().optional(),
	query: z
		.object({
			limit: z.number().optional(),
			subject: z.string().optional().nullable(),
			any_email: z.string().optional().nullable(),
			from_: z.string().optional().nullable(),
			to: z.string().optional().nullable(),
			read: z.boolean().optional().nullable(),
		})
		.optional(),
})

export const getThreadDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		thread_id: z.string(),
	}),
	query: z.never().optional(),
})

export const setThreadReadStatusDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		thread_id: z.string(),
	}),
	query: z.object({
		read: z.boolean(),
	}),
})

export const readEmailsDataSchema = z.object({
	body: z.never().optional(),
	path: z.never().optional(),
	query: z.object({
		thread_id: z.string(),
	}),
})

export const listEmailMessagesDataSchema = z.object({
	body: listEmailMessageParamsInputSchema,
})

export const storeInboxDataSchema = z.object({
	body: nylasStoreInboxInputSchema,
})

export const listEmailThreadParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(orderingSchema).optional(),
	filters: emailThreadFiltersSchema,
})

export const getEmailMessageDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		email_message_id: z.string(),
	}),
	query: z
		.object({
			load_body: z.boolean().optional(),
		})
		.optional(),
})

export const getEmailAttachmentDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		email_message_id: z.string(),
		attachment_id: z.string(),
	}),
	query: z.never().optional(),
})

export const getCandidateEmailsEmailAddressesCandidateEmailsCandidateIdGetDataSchema =
	z.object({
		body: z.never().optional(),
		path: z.object({
			candidate_id: z.string(),
		}),
		query: z.never().optional(),
	})

export const createCandidateEmailsDataSchema = z.object({
	body: z.array(createCandidateEmailAddressItemParamsSchema),
})

export const updateCandidateEmailByCandidateIdAndEmailAddressDataSchema =
	z.object({
		body: updateCandidateEmailAddressRequestSchema,
	})

export const updateCandidateEmailDataSchema = z.object({
	body: updateCandidateEmailAddressItemParamsSchema,
	path: z.object({
		candidate_email_item_id: z.string(),
	}),
	query: z.never().optional(),
})

export const lisEmailAddressFetchParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(emailAddressFetchOrderingSchema).optional(),
	filters: emailAddressFetchFiltersSchema.optional(),
})

export const bulkEmailaddressFetchesDataSchema = z.object({
	body: bulkEmailAddressFetchRequestSchema,
})

export const emailaddressFetchDataSchema = z.object({
	body: emailAddressFetchRequestSchema,
})

export const signalHireDataSchema = z.object({
	body: signalHireRequestSchema,
})

export const apolloPeopleMatchDataSchema = z.object({
	body: apolloPeopleMatchRequestSchema,
})

export const mixrankPersonSocialUrlLookupDataSchema = z.object({
	body: mixrankPersonSocialUrlLookupRequestSchema,
})

export const nubelaPersonEmailDataSchema = z.object({
	body: nubelaPersonalEmailRequestSchema,
})

export const listUserEmailAccountParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(userEmailAccountOrderingSchema).optional(),
	filters: userEmailAccountFiltersSchema.optional(),
	include_warmy: z.boolean().optional(),
})

export const getUserEmailAccountDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		account_id: z.string(),
	}),
	query: z
		.object({
			include_warmy: z.boolean().optional().nullable(),
		})
		.optional(),
})

export const updateUserEmailAccountDataSchema = z.object({
	body: updateUserEmailAccountParamsSchema,
	path: z.object({
		account_id: z.string(),
	}),
	query: z.never().optional(),
})

export const deactivateUserEmailAccountDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		account_id: z.string(),
	}),
	query: z.never().optional(),
})

export const listUserEmailAddressParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(userEmailAddressOrderingSchema).optional(),
	filters: userEmailAddressFiltersSchema.optional(),
})

export const createUserEmailAliasDataSchema = z.object({
	body: createUserEmailAliasParamsSchema,
})

export const createEmailGroupDataSchema = z.object({
	body: createEmailGroupParamsSchema,
})

export const addAccountToEmailGroupDataSchema = z.object({
	body: emailGroupItemParamsSchema,
})

export const addBatchOfAccountsToEmailGroupDataSchema = z.object({
	body: emailGroupItemBatchParamsSchema,
})

export const removeAccountFromEmailGroupDataSchema = z.object({
	body: emailGroupItemParamsSchema,
})

export const getEmailGroupDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		email_group_id: z.string(),
	}),
	query: z
		.object({
			valid_only: z.boolean().optional().nullable(),
			include_warmy: z.boolean().optional().nullable(),
		})
		.optional(),
})

export const listEmailGroupsDataSchema = z.object({
	body: listEmailGroupParamsInputSchema,
})

export const getAccountsDailyAnalyticsDataSchema = z.object({
	body: emailAccountAnalyticsParamsSchema,
})

export const getEmailGroupDailyAnalyticsDataSchema = z.object({
	body: emailGroupAnalyticsParamsSchema,
})

export const bulkUpdateUserEmailAccountParamsSchema = z.object({
	inbox_warm: z.boolean().optional().nullable(),
	daily_limit: z.number().optional().nullable(),
	warmup_filter_tags: z.array(z.string()).optional().nullable(),
	first_name: z.string().optional().nullable(),
	last_name: z.string().optional().nullable(),
	password: z.string().optional().nullable(),
	account_type: emailAccountTypeSchema.optional().nullable(),
	user_email_account_ids: z.array(z.string()),
})

export const createRegistrarAccountParamsSchema = z.object({
	name: z.string(),
	registrar: registrarSchema,
	credentials: z.union([
		z
			.object({
				type: z.literal('GODADDY').optional(),
			})
			.and(goDaddyCredentialsSchema),
		z
			.object({
				type: z.literal('ROUTE53').optional(),
			})
			.and(route53CredentialsSchema),
	]),
})

export const getRegistrarAccountDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		registrar_id: z.string(),
	}),
	query: z
		.object({
			include_metrics: z.boolean().optional().nullable(),
		})
		.optional(),
})

export const updateRegistrarAccountDataSchema = z.object({
	body: updateRegistrarAccountParamsSchema,
	path: z.object({
		registrar_id: z.string(),
	}),
	query: z.never().optional(),
})

export const resyncRegistrarAccountDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		registrar_id: z.string(),
	}),
	query: z.never().optional(),
})

export const listRegistrarParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(registrarOrderingSchema).optional(),
	filters: listRegistrarFiltersSchema.optional(),
	include_metrics: z.boolean().optional(),
})

export const listDomainsDataSchema = z.object({
	body: listDomainParamsInputSchema,
})

export const getDomainDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		domain_id: z.string(),
	}),
	query: z
		.object({
			include_metrics: z.boolean().optional().nullable(),
		})
		.optional(),
})

export const resyncDomainDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		domain_id: z.string(),
	}),
	query: z.never().optional(),
})

export const assignDomainToOrganizationDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		domain_id: z.string(),
		organization_id: z.string(),
	}),
	query: z.never().optional(),
})

export const removeDomainFromOrganizationDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		domain_id: z.string(),
	}),
	query: z.never().optional(),
})

export const createUnmanagedDomainDataSchema = z.object({
	body: createUnmanagedDomainParamsSchema,
})

export const listDomainDnsRecordsDataSchema = z.object({
	body: listDnsRecordParamsInputSchema,
	path: z.object({
		domain_id: z.string(),
	}),
	query: z.never().optional(),
})

export const createNotificationTemplateSchema = z.object({
	type: templateTypeSchema,
	name: z.string(),
	code: notificationTemplateCodeSchema.optional().nullable(),
	from_: z.string().optional().nullable(),
	subject: z.string().optional().nullable(),
	html_content: z.string().optional().nullable(),
	text_content: z.string().optional().nullable(),
	push_content: z.object({}).passthrough().optional().nullable(),
	tags: z.array(z.string()).optional(),
	template_schema: z.object({}).passthrough().optional().nullable(),
	provider_template_id: z.string().optional().nullable(),
})

export const getNotificationTemplateDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		template_id: z.string(),
	}),
	query: z.never().optional(),
})

export const updateNotificationTemplateDataSchema = z.object({
	body: updateNotificationTemplateSchema,
	path: z.object({
		template_id: z.string(),
	}),
	query: z.never().optional(),
})

export const listNotificationTemplateParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(notificationTemplateOrderingSchema).optional(),
	filters: notificationTemplateFiltersSchema.optional(),
})

export const getNotificationDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		notification_id: z.string(),
	}),
	query: z.never().optional(),
})

export const getMatchDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		match_id: z.string(),
	}),
	query: z
		.object({
			include_candidate: z.boolean().optional().nullable(),
			include_sequence_template: z.boolean().optional().nullable(),
		})
		.optional(),
})

export const listMatchesDataSchema = z.object({
	body: listCandidateMatchParamsInputSchema,
})

export const deleteMatchesDataSchema = z.object({
	body: deleteMatchesRequestSchema,
})

export const getCandidateMatchCountsForJobDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		job_id: z.string(),
	}),
	query: z
		.object({
			internal: z.boolean().optional().nullable(),
		})
		.optional(),
})

export const shortlistCandidatesDataSchema = z.object({
	body: shortlistOrRejectCandidatesRequestSchema,
})

export const rejectCandidatesDataSchema = z.object({
	body: shortlistOrRejectCandidatesRequestSchema,
})

export const recordHumanReviewOnMatchDataSchema = z.object({
	body: humanReviewRequestSchema,
	path: z.object({
		match_id: z.string(),
	}),
	query: z.never().optional(),
})

export const recordClientDecisionOnMatchDataSchema = z.object({
	body: recordClientDecisionRequestSchema,
	path: z.object({
		match_id: z.string(),
	}),
	query: z.never().optional(),
})

export const listCandidateMatchTransitionsDataSchema = z.object({
	body: listCandidateMatchTransitionsParamsInputSchema,
	path: z.object({
		match_id: z.string(),
	}),
	query: z.never().optional(),
})

export const getCandidateMatchTransitionDataSchema = z.object({
	body: z.never().optional(),
	path: z.object({
		transition_id: z.string(),
	}),
	query: z.never().optional(),
})

export const candidateScoresItemV2Schema = z.object({
	candidate_id: z.string(),
	must_have_classification: z.array(requirementClassificationV2Schema),
	nice_have_classification: z.array(requirementClassificationV2Schema),
	config: candidateToScoreV2ConfigOutputSchema.optional().nullable(),
})

export const candidateSchema = z.object({
	id: z.string(),
	external_id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	owner_id: z.string(),
	name: z.string().optional().nullable(),
	first_name: z.string().optional().nullable(),
	last_name: z.string().optional().nullable(),
	job_title: z.string().optional().nullable(),
	current_company: z.string().optional().nullable(),
	locality: z.string().optional().nullable(),
	phone_number: z.string().optional().nullable(),
	links: z.record(z.string()).optional().nullable(),
	candidate_tags: z.array(z.string()).optional().nullable(),
	search_criteria: z.record(z.array(z.string())).optional().nullable(),
	notes: z.string().optional().nullable(),
	min_comp_expectations: z.number().optional().nullable(),
	max_comp_expectations: z.number().optional().nullable(),
	equity_expectations: z.number().optional().nullable(),
	last_fetched_time: z.string().optional().nullable(),
	email_address_fetch_status: emailAddressFetchStatusSchema
		.optional()
		.nullable(),
	owner: userSchema.optional().nullable(),
	owner_name: z.string().nullable(),
	owner_picture: z.string().nullable(),
})

export const candidateEmailAddressItemSchema = z.object({
	id: z.string(),
	candidate_id: z.string(),
	email_address_id: z.number(),
	label: candidateEmailAddressLabelSchema.optional().nullable(),
	status: candidateEmailAddressStatusSchema.optional().nullable(),
	status_update_message_id: z.string().optional().nullable(),
	joined_email_address: emailAddressSchema,
})

export const emailResponseClassificationSchema = z.object({
	action: emailActionSchema,
	has_questions: z.boolean().optional().nullable(),
	wrong_email: z.boolean().optional().nullable(),
	candidate_info_update: candidateInfoUpdateSchema.optional().nullable(),
	email_predicted_metadata: emailAnalysisResponseV2Schema
		.optional()
		.nullable(),
})

export const jobSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	name: z.string(),
	owner_id: z.string(),
	job_title: z.string(),
	company_name: z.string(),
	status: z.union([
		z.literal('Active'),
		z.literal('Passive'),
		z.literal('Paused'),
		z.literal('Won'),
		z.literal('Lost'),
	]),
	status_reason: z.string().nullable(),
	job_description: z.string().nullable(),
	client_intake_notes: z.string().nullable(),
	recruiter_notes: z.string().nullable(),
	links: z.record(z.string()).nullable(),
	job_summary_id: z.string().nullable(),
	score_custom_instructions: z.string().optional().nullable(),
	organization_id: z.string(),
	hiring_manager_name: z.string().optional().nullable(),
	notify_candidate_review: z.array(z.string()).optional().nullable(),
	notify_introduction: z.array(z.string()).optional().nullable(),
	notify_weekly_update: z.array(z.string()).optional().nullable(),
	notify_weekly_update_template_id: z.string().optional().nullable(),
	email_group_id: z.string().optional().nullable(),
	weekly_outreach_quota: z.number().optional(),
	owner: userSchema.optional().nullable(),
	latest_job_summary: jobSummaryOutputSchema.optional().nullable(),
	team: z.array(userSchema).optional(),
	owner_name: z.string().nullable(),
	owner_picture: z.string().nullable(),
	team_members: z.array(userSchema),
})

export const scheduledSequenceSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	template_id: z.string().optional().nullable(),
	user_email_address_id: z.string().optional().nullable(),
	user_email_account_id: z.string().optional().nullable(),
	user_id: z.string(),
	candidate_id: z.string(),
	job_id: z.string(),
	sender_email: z.string().optional().nullable(),
	recipient_email: z.string().optional().nullable(),
	subject: z.string(),
	emails: z.array(sequenceDraftSchema),
	thread_id: z.string().optional().nullable(),
	status: sequenceStatusSchema,
	num_steps_completed: z.number(),
	nylas_draft_id: z.string().nullable(),
	queued: z.boolean(),
	next_deliver_date: z.string().optional().nullable(),
	last_contacted_date: z.string().optional().nullable(),
	template: sequenceTemplateSchema.optional().nullable(),
	next_deliver_at: z.string().nullable(),
})

export const completeCandidateSchema = z.object({
	id: z.string(),
	external_id: z.string(),
	created_at: z.string(),
	updated_at: z.string().optional().nullable(),
	owner_id: z.string(),
	name: z.string().optional().nullable(),
	first_name: z.string().optional().nullable(),
	last_name: z.string().optional().nullable(),
	job_title: z.string().optional().nullable(),
	current_company: z.string().optional().nullable(),
	locality: z.string().optional().nullable(),
	phone_number: z.string().optional().nullable(),
	links: z.record(z.string()).optional().nullable(),
	candidate_tags: z.array(z.string()).optional().nullable(),
	search_criteria: z.record(z.array(z.string())).optional().nullable(),
	notes: z.string().optional().nullable(),
	min_comp_expectations: z.number().optional().nullable(),
	max_comp_expectations: z.number().optional().nullable(),
	equity_expectations: z.number().optional().nullable(),
	last_fetched_time: z.string().optional().nullable(),
	email_address_fetch_status: emailAddressFetchStatusSchema
		.optional()
		.nullable(),
	owner: userSchema.optional().nullable(),
	mhid: z.string(),
	build_id: z.number().optional().nullable(),
	internal_primary_key: z.string().optional().nullable(),
	update_metadata: nullableUpdateMetaSchema.optional().nullable(),
	raw_ids: z.array(z.string().nullable()).optional().nullable(),
	mixrank_slug_status: z.string().optional().nullable(),
	linkedin_username: z.string().optional().nullable(),
	linkedin_usernames: z.array(z.string().nullable()).optional().nullable(),
	linkedin_id: z.number().optional().nullable(),
	linkedin_ids: z.array(z.number().nullable()).optional().nullable(),
	pdl_id: z.string().optional().nullable(),
	pdl_ids: z.array(z.string().nullable()).optional().nullable(),
	names: z.array(z.string().nullable()).optional().nullable(),
	industry: z.string().optional().nullable(),
	country: z.string().optional().nullable(),
	country_code: z.string().optional().nullable(),
	location_geo_coordinate: z.string().optional().nullable(),
	pdl_metro: z.string().optional().nullable(),
	skills: z.array(z.string().nullable()).optional().nullable(),
	linkedin_follower_count: z.number().optional().nullable(),
	linkedin_connections_count: z.number().optional().nullable(),
	github_username: z.string().optional().nullable(),
	github_usernames: z.array(z.string().nullable()).optional().nullable(),
	email: z.string().optional().nullable(),
	emails: z.array(z.string().nullable()).optional().nullable(),
	user_website: z.string().optional().nullable(),
	user_websites: z.array(z.string().nullable()).optional().nullable(),
	github_followers: z.number().optional().nullable(),
	github_following: z.number().optional().nullable(),
	github_repositories_count: z.number().optional().nullable(),
	github_repositories_own_count: z.number().optional().nullable(),
	github_repositories_forked_count: z.number().optional().nullable(),
	github_repositories_own: z
		.array(z.string().nullable())
		.optional()
		.nullable(),
	github_repositories_forked: z
		.array(z.string().nullable())
		.optional()
		.nullable(),
	github_contributions_made_last_year: z.number().optional().nullable(),
	github_bio: z.string().optional().nullable(),
	github_achievements: z.array(z.string().nullable()).optional().nullable(),
	gscholar_id: z.string().optional().nullable(),
	gscholar_ids: z.array(z.string().nullable()).optional().nullable(),
	gscholar_avatar_url: z.string().optional().nullable(),
	gscholar_title: z.string().optional().nullable(),
	gscholar_labels: z.array(z.string().nullable()).optional().nullable(),
	gscholar_citations_total: z.number().optional().nullable(),
	gscholar_citations_per_year: z
		.array(nullableCitationsForYearSchema.nullable())
		.optional()
		.nullable(),
	gscholar_h_index: z.number().optional().nullable(),
	gscholar_i10_index: z.number().optional().nullable(),
	gscholar_articles: z
		.array(nullableGScholarArticleSchema.nullable())
		.optional()
		.nullable(),
	twitter_username: z.string().optional().nullable(),
	twitter_usernames: z.array(z.string().nullable()).optional().nullable(),
	twitter_followers_count: z.number().optional().nullable(),
	mixrank_id: z.string().optional().nullable(),
	mixrank_ids: z.array(z.string().nullable()).optional().nullable(),
	url: z.string().optional().nullable(),
	linkedin_url: z.string().optional().nullable(),
	twitter_url: z.string().optional().nullable(),
	github_url: z.string().optional().nullable(),
	gscholar_citations_url: z.string().optional().nullable(),
	city: z.string().optional().nullable(),
	state: z.string().optional().nullable(),
	description: z.string().optional().nullable(),
	headline: z.string().optional().nullable(),
	profile_pic: z.string().optional().nullable(),
	pdl_job_last_verified: z.string().optional().nullable(),
	education: z
		.array(nullableEducationSchema.nullable())
		.optional()
		.nullable(),
	experience: z
		.array(nullableExperienceSchema.nullable())
		.optional()
		.nullable(),
	years_of_experience: z.number().optional().nullable(),
	is_employed: z.boolean().optional().nullable(),
	role: z.array(nullableRoleHistorySchema.nullable()).optional().nullable(),
	company: z
		.array(nullableCompanyHistorySchema.nullable())
		.optional()
		.nullable(),
	all_schools: z.array(z.string().nullable()).optional().nullable(),
	courses: z.array(nullableCourseSchema.nullable()).optional().nullable(),
	certifications: z
		.array(nullableCertificationSchema.nullable())
		.optional()
		.nullable(),
	languages: z.array(nullableLanguageSchema.nullable()).optional().nullable(),
	recommendations: z
		.array(nullableRecommendationSchema.nullable())
		.optional()
		.nullable(),
	articles: z.array(nullableArticleSchema.nullable()).optional().nullable(),
	awards: z.array(nullableAwardSchema.nullable()).optional().nullable(),
	patents: z.array(nullablePatentSchema.nullable()).optional().nullable(),
	projects: z.array(nullableProjectSchema.nullable()).optional().nullable(),
	volunteering: z
		.array(nullableVolunteeringSchema.nullable())
		.optional()
		.nullable(),
	publications: z
		.array(nullablePublicationSchema.nullable())
		.optional()
		.nullable(),
	stats: nullableStatsSchema.optional().nullable(),
	version_id: z.number().optional().nullable(),
	demographics: nullableDemographicsSchema.optional().nullable(),
	is_veteran: z.number().optional().nullable(),
	has_security_clearance: z.number().optional().nullable(),
	profile_completeness_score: z.number().optional().nullable(),
	education_prestige: z.number().optional().nullable(),
	candidate_flags: z.array(z.string().nullable()).optional().nullable(),
	primary_email: z.string().optional().nullable(),
	secondary_emails: z.array(z.string()).optional().nullable(),
	owner_name: z.string().nullable(),
	owner_picture: z.string().nullable(),
})

export const fESearchFiltersOutputSchema = z.object({
	top_level: topLevelParametersOutputSchema.optional().nullable(),
	education: z.array(educationParametersOutputSchema).optional().nullable(),
	experience: z.array(experienceParametersOutputSchema).optional().nullable(),
	work_and_publications: workAndPublicationsCollapseOutputSchema
		.optional()
		.nullable(),
	candidate_details: candidateDetailsCollapseSchema.optional().nullable(),
})

export const searchScoreWorkflowInputSchema = z.object({
	state: searchScoreWorkflowStateSchema.optional(),
	job_summary_id: z.string(),
	limit: z.number().optional(),
	workflow_run_id: z.string().optional().nullable(),
	config: candidateToScoreV2ConfigInputSchema.optional().nullable(),
	metrics: searchScoreMetricsSchema.optional().nullable(),
})

export const emailAddressFetchDataSchema = z.object({
	apollo: z
		.array(emailAddressFetchResponseSchema.nullable())
		.optional()
		.nullable(),
	mixrank: z
		.array(emailAddressFetchResponseSchema.nullable())
		.optional()
		.nullable(),
	nubela: z
		.array(emailAddressFetchResponseSchema.nullable())
		.optional()
		.nullable(),
	signal_hire: z
		.array(emailAddressFetchResponseSchema.nullable())
		.optional()
		.nullable(),
})

export const jobOutreachMetricsSchema = z.object({
	sequence: sequenceMetricsSchema,
	replies: z.array(replyMetricsSchema),
	templates: z.array(templateMetricsSchema),
	email_accounts: z.array(emailAccountMetricsSchema),
})

export const jobWithMetricsSchema = z.object({
	candidates_needs_review: z.number(),
	candidates_outreached: z.number(),
	candidates_replied: z.number(),
	candidates_interested: z.number(),
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	name: z.string(),
	owner_id: z.string(),
	job_title: z.string(),
	company_name: z.string(),
	status: z.union([
		z.literal('Active'),
		z.literal('Passive'),
		z.literal('Paused'),
		z.literal('Won'),
		z.literal('Lost'),
	]),
	status_reason: z.string().nullable(),
	job_description: z.string().nullable(),
	client_intake_notes: z.string().nullable(),
	recruiter_notes: z.string().nullable(),
	links: z.record(z.string()).nullable(),
	job_summary_id: z.string().nullable(),
	score_custom_instructions: z.string().optional().nullable(),
	organization_id: z.string(),
	hiring_manager_name: z.string().optional().nullable(),
	notify_candidate_review: z.array(z.string()).optional().nullable(),
	notify_introduction: z.array(z.string()).optional().nullable(),
	notify_weekly_update: z.array(z.string()).optional().nullable(),
	notify_weekly_update_template_id: z.string().optional().nullable(),
	email_group_id: z.string().optional().nullable(),
	weekly_outreach_quota: z.number().optional(),
	owner: userSchema.optional().nullable(),
	latest_job_summary: jobSummaryOutputSchema.optional().nullable(),
	team: z.array(userSchema).optional(),
	response_rate: z.number(),
	owner_name: z.string().nullable(),
	owner_picture: z.string().nullable(),
	team_members: z.array(userSchema),
})

export const jobWithWeeklyQuotaSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	name: z.string(),
	owner_id: z.string(),
	job_title: z.string(),
	company_name: z.string(),
	status: z.union([
		z.literal('Active'),
		z.literal('Passive'),
		z.literal('Paused'),
		z.literal('Won'),
		z.literal('Lost'),
	]),
	status_reason: z.string().nullable(),
	job_description: z.string().nullable(),
	client_intake_notes: z.string().nullable(),
	recruiter_notes: z.string().nullable(),
	links: z.record(z.string()).nullable(),
	job_summary_id: z.string().nullable(),
	score_custom_instructions: z.string().optional().nullable(),
	organization_id: z.string(),
	hiring_manager_name: z.string().optional().nullable(),
	notify_candidate_review: z.array(z.string()).optional().nullable(),
	notify_introduction: z.array(z.string()).optional().nullable(),
	notify_weekly_update: z.array(z.string()).optional().nullable(),
	notify_weekly_update_template_id: z.string().optional().nullable(),
	email_group_id: z.string().optional().nullable(),
	weekly_outreach_quota: z.number().optional(),
	owner: userSchema.optional().nullable(),
	latest_job_summary: jobSummaryOutputSchema.optional().nullable(),
	team: z.array(userSchema).optional(),
	candidates_outreached_weekly: z.number(),
	owner_name: z.string().nullable(),
	owner_picture: z.string().nullable(),
	team_members: z.array(userSchema),
})

export const notificationFiltersSchema = z.object({
	ids: z.array(z.string()).optional().nullable(),
	match_id: z.string().optional().nullable(),
	channel: notificationChannelSchema.optional().nullable(),
	status: notificationStatusSchema.optional().nullable(),
	template_id: z.string().optional().nullable(),
})

export const listNotificationParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(notificationOrderingSchema).optional(),
	filters: notificationFiltersSchema.optional(),
})

export const listScheduledSequenceParamsOutputSchema = z.object({
	pagination: paginationOutputSchema.optional(),
	ordering: z.array(scheduledSequenceOrderingSchema).optional(),
	filters: scheduledSequenceFiltersSchema.optional(),
	include_template: z.boolean().optional().nullable(),
})

export const paginatedListCompleteCandidatesSchema = z.object({
	entries: z.array(completeCandidateSchema),
	metadata: listCandidateParamsOutputSchema,
})

export const paginatedListDnsRecordsSchema = z.object({
	entries: z.array(dnsRecordSchema),
	metadata: listDnsRecordParamsOutputSchema,
})

export const paginatedListDomainsSchema = z.object({
	entries: z.array(z.union([domainSchema, domainWithMetricsSchema])),
	metadata: listDomainParamsOutputSchema,
})

export const emailAddressFetchSchema = z.object({
	id: z.string(),
	candidate_id: z.string(),
	workflow_run_id: z.string(),
	status: emailAddressFetchStatusSchema,
	data: emailAddressFetchDataSchema.optional().nullable(),
	failure_reason: z.string().nullable(),
	created_at: z.string(),
	updated_at: z.string(),
})

export const paginatedListEmailMessageSchema = z.object({
	entries: z.array(emailMessageSchema),
	metadata: listEmailMessageParamsOutputSchema,
})

export const paginatedListJobSummariesSchema = z.object({
	entries: z.array(jobSummaryOutputSchema),
	metadata: queryParamsOutputSchema,
})

export const paginatedListJobsSchema = z.object({
	entries: z.array(z.union([jobSchema, jobWithMetricsSchema])),
	metadata: listJobParamsOutputSchema,
})

export const paginatedListNotificationSchema = z.object({
	entries: z.array(notificationSchema),
	metadata: listNotificationParamsOutputSchema,
})

export const paginatedListRegistrarsSchema = z.object({
	entries: z.array(
		z.union([registrarAccountSchema, registrarAccountWithMetricsSchema]),
	),
	metadata: listRegistrarParamsOutputSchema,
})

export const paginatedListScheduledSequenceSchema = z.object({
	entries: z.array(scheduledSequenceSchema),
	metadata: listScheduledSequenceParamsOutputSchema,
})

export const searchSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	user_id: z.string(),
	job_id: z.string(),
	search_filters: fESearchFiltersOutputSchema,
	total_count: z.number().nullable(),
	total_count_limit: z.union([z.literal('eq'), z.literal('gte')]).nullable(),
	name: z.string().nullable(),
	starred: z.boolean().nullable(),
	iterator_mhid: z.string().nullable(),
	score_workflow: searchScoreWorkflowOutputSchema.nullable(),
	job_summary_id: z.string().nullable(),
	parent_search_id: z.string().nullable(),
	score_count: z.number().nullable(),
	score_offset: z.number().nullable(),
})

export const paginatedListSequenceTemplateSchema = z.object({
	entries: z.array(sequenceTemplateSchema),
	metadata: listSequenceTemplateParamsOutputSchema,
})

export const userEmailAccountSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	user_id: z.string().nullable(),
	email_address: z.string(),
	external_id: z.string().optional().nullable(),
	provider: accountProviderSchema.optional(),
	deactivated_at: z.string().optional().nullable(),
	disconnected_at: z.string().optional().nullable(),
	inbox_warm: z.boolean(),
	daily_limit: z.number().optional(),
	warmup_filter_tags: z.array(z.string()).optional().nullable(),
	first_name: z.string().optional().nullable(),
	last_name: z.string().optional().nullable(),
	account_type: emailAccountTypeSchema.optional().nullable(),
	warmy_mailbox: warmyMailboxSchema.optional().nullable(),
	full_name: z.string().nullable(),
	has_password: z.boolean(),
})

export const paginatedListUserEmailAddressSchema = z.object({
	entries: z.array(userEmailAddressSchema),
	metadata: listUserEmailAddressParamsOutputSchema,
})

export const userInviteWithRelationsSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	email: z.string(),
	organization_id: z.string(),
	status: inviteStatusSchema,
	role: userRoleSchema,
	created_by: z.string(),
	expires_at: z.string(),
	last_sent_at: z.string(),
	resend_count: z.number(),
	accepted_at: z.string().optional().nullable(),
	accepted_by: z.string().optional().nullable(),
	revoked_at: z.string().optional().nullable(),
	revoked_by: z.string().optional().nullable(),
	creator: userSchema,
	organization: organizationSchema,
	acceptor: userSchema.optional().nullable(),
	revoker: userSchema.optional().nullable(),
})

export const paginatedListUsersSchema = z.object({
	entries: z.array(z.union([userSchema, userWithMetricsSchema])),
	metadata: listUserParamsOutputSchema,
})

export const schedulingDataPreviewSchema = z.object({
	valid_accounts: z.array(accountPreviewSchema),
	daily_stats: dailyStatsPreviewSchema,
	remaining_days_in_week: z.array(z.string()),
	job_quota_data: jobQuotaDataSchema,
	sequence_allocation: sequenceAllocationPreviewSchema,
	available_sequences: z.record(z.record(jobSequencesPreviewSchema)),
})

export const childSearchSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	user_id: z.string(),
	job_id: z.string(),
	search_filters: fESearchFiltersOutputSchema,
	total_count: z.number().nullable(),
	total_count_limit: z.union([z.literal('eq'), z.literal('gte')]).nullable(),
	name: z.string().nullable(),
	starred: z.boolean().nullable(),
	iterator_mhid: z.string().nullable(),
	score_workflow: searchScoreWorkflowOutputSchema,
	job_summary_id: z.string(),
	parent_search_id: z.string(),
	score_count: z.number(),
	score_offset: z.number(),
})

export const searchFiltersSchema = z.object({
	education: z.array(educationParametersOutputSchema).optional().nullable(),
	experience: z.array(experienceParametersOutputSchema).optional().nullable(),
	publications: publicationsParametersOutputSchema.optional().nullable(),
	github: githubParametersOutputSchema.optional().nullable(),
	miscellaneous: miscellaneousParametersSchema.optional().nullable(),
	criteria: criteriaParametersSchema.optional().nullable(),
	location: locationParametersSchema.optional().nullable(),
})

export const searchResultCandidateSchema = z.object({
	index: z.string().optional().nullable(),
	id: z.string().optional().nullable(),
	score: z.number().optional().nullable(),
	ignored: z.array(z.string()).optional().nullable(),
	source: personModelBackwardsCompatibleSchema.optional().nullable(),
	highlights: z
		.record(
			z.union([
				z.string(),
				z.array(
					z
						.union([z.string(), z.array(z.string().nullable())])
						.nullable(),
				),
			]),
		)
		.optional()
		.nullable(),
	status: candidateMatchStatusSchema.optional().nullable(),
	matched_queries: z.array(z.string()).optional().nullable(),
})

export const searchRunScoreWorkflowSchema = z.object({
	state: searchRunScoreWorkflowStateSchema.optional(),
	job_summary_id: z.string(),
	limit: z.number().optional(),
	workflow_run_id: z.string().optional().nullable(),
	config: candidateToScoreV2ConfigOutputSchema.optional().nullable(),
	metrics: searchScoreMetricsSchema.optional().nullable(),
})

export const listOrganizationsDataSchema = z.object({
	body: listOrganizationParamsInputSchema,
})

export const listUsersDataSchema = z.object({
	body: listUserParamsInputSchema,
})

export const createUserInviteDataSchema = z.object({
	body: createUserInviteRequestSchema,
})

export const listUserInvitesDataSchema = z.object({
	body: listUserInviteParamsInputSchema,
})

export const listSearchesDataSchema = z.object({
	body: listSearchParamsInputSchema,
})

export const buildFiltersDataSchema = z.object({
	body: buildFiltersRequestSchema,
})

export const getBackendSearchFiltersDataSchema = z.object({
	body: fESearchFiltersInputSchema,
})

export const createSearchRequestSchema = z.object({
	job_id: z.string(),
	search_filters: fESearchFiltersInputSchema,
	score_workflow: searchScoreWorkflowInputSchema.optional().nullable(),
})

export const listSearchRunsDataSchema = z.object({
	body: listSearchRunParamsInputSchema,
})

export const updateSearchRunDataSchema = z.object({
	body: updateSearchRunParamsSchema,
	path: z.object({
		search_run_id: z.string(),
	}),
	query: z.never().optional(),
})

export const searchCandidateExcludeRejectedDataSchema = z.object({
	body: candidateSearchRequestSchema,
	path: z.object({
		job_id: z.string(),
	}),
	query: z.never().optional(),
})

export const scoreCandidateDataSchema = z.object({
	body: scoreCandidateRequestSchema,
})

export const createScheduledSequenceRawDataSchema = z.object({
	body: createScheduledSequenceRawRequestSchema,
})

export const listScheduledSequencesDataSchema = z.object({
	body: listScheduledSequenceParamsInputSchema,
})

export const listSequenceTemplatesDataSchema = z.object({
	body: listSequenceTemplateParamsInputSchema,
})

export const sendEmailDataSchema = z.object({
	body: nylasCreateDraftRequestSchema,
})

export const listEmailThreadsDataSchema = z.object({
	body: listEmailThreadParamsInputSchema,
})

export const listEmailaddressFetchesDataSchema = z.object({
	body: lisEmailAddressFetchParamsInputSchema,
})

export const listUserEmailAccountsDataSchema = z.object({
	body: listUserEmailAccountParamsInputSchema,
})

export const listUserEmailAddressesDataSchema = z.object({
	body: listUserEmailAddressParamsInputSchema,
})

export const bulkUpdateUserEmailAccountDataSchema = z.object({
	body: bulkUpdateUserEmailAccountParamsSchema,
})

export const createRegistrarAccountDataSchema = z.object({
	body: createRegistrarAccountParamsSchema,
})

export const listRegistrarsDataSchema = z.object({
	body: listRegistrarParamsInputSchema,
})

export const createNotificationTemplateDataSchema = z.object({
	body: createNotificationTemplateSchema,
})

export const listNotificationTemplatesDataSchema = z.object({
	body: listNotificationTemplateParamsInputSchema,
})

export const listNotificationParamsInputSchema = z.object({
	pagination: paginationInputSchema.optional(),
	ordering: z.array(notificationOrderingSchema).optional(),
	filters: notificationFiltersSchema.optional(),
})

export const augmentedSearchResultCandidateSchema = z.object({
	index: z.string().optional().nullable(),
	id: z.string().optional().nullable(),
	score: z.number().optional().nullable(),
	ignored: z.array(z.string()).optional().nullable(),
	source: personModelBackwardsCompatibleSchema.optional().nullable(),
	highlights: z
		.record(
			z.union([
				z.string(),
				z.array(
					z
						.union([z.string(), z.array(z.string().nullable())])
						.nullable(),
				),
			]),
		)
		.optional()
		.nullable(),
	status: candidateMatchStatusSchema.optional().nullable(),
	matched_queries: z.array(z.string()).optional().nullable(),
	match_state: candidateStateSchema.optional().nullable(),
	assessment_result: candidateScoresItemV2Schema.optional().nullable(),
	client_state: clientCandidateStateSchema.nullable(),
	internal_state: internalCandidateStateSchema.nullable(),
	is_match_deletable: z.boolean().nullable(),
})

export const candidateMatchSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	job_id: z.string(),
	candidate_id: z.string(),
	latest_user_id: z.string(),
	latest_search_id: z.string(),
	latest_sequence_id: z.string().optional().nullable(),
	message_id: z.string().optional().nullable(),
	state: candidateStateSchema,
	sentiment_result: emailResponseClassificationSchema.optional().nullable(),
	review_action: emailActionSchema.optional().nullable(),
	human_review_action: emailActionSchema.optional().nullable(),
	reviewer_id: z.string().optional().nullable(),
	human_review_notes: z.string().optional().nullable(),
	client_id: z.string().optional().nullable(),
	client_decision: clientDecisionSchema.optional().nullable(),
	introduction_email: candidateOutreachEmailSchema.optional().nullable(),
	decline_email: candidateOutreachEmailSchema.optional().nullable(),
	reject_email: candidateOutreachEmailSchema.optional().nullable(),
	view_count: z.number().optional(),
	reminder_count: z.number().optional(),
	human_review_duration_seconds: z.number().optional().nullable(),
	client_review_duration_seconds: z.number().optional().nullable(),
	total_duration_seconds: z.number().optional().nullable(),
	error_message: z.string().optional().nullable(),
	job: jobSchema.optional().nullable(),
	organization: organizationSchema.optional().nullable(),
	latest_sequence: scheduledSequenceSchema.optional().nullable(),
	client_state: clientCandidateStateSchema.nullable(),
	internal_state: internalCandidateStateSchema.nullable(),
	is_deletable: z.boolean(),
})

export const candidateMatchEnrichedSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	job_id: z.string(),
	candidate_id: z.string(),
	latest_user_id: z.string(),
	latest_search_id: z.string(),
	latest_sequence_id: z.string().optional().nullable(),
	message_id: z.string().optional().nullable(),
	state: candidateStateSchema,
	sentiment_result: emailResponseClassificationSchema.optional().nullable(),
	review_action: emailActionSchema.optional().nullable(),
	human_review_action: emailActionSchema.optional().nullable(),
	reviewer_id: z.string().optional().nullable(),
	human_review_notes: z.string().optional().nullable(),
	client_id: z.string().optional().nullable(),
	client_decision: clientDecisionSchema.optional().nullable(),
	introduction_email: candidateOutreachEmailSchema.optional().nullable(),
	decline_email: candidateOutreachEmailSchema.optional().nullable(),
	reject_email: candidateOutreachEmailSchema.optional().nullable(),
	view_count: z.number().optional(),
	reminder_count: z.number().optional(),
	human_review_duration_seconds: z.number().optional().nullable(),
	client_review_duration_seconds: z.number().optional().nullable(),
	total_duration_seconds: z.number().optional().nullable(),
	error_message: z.string().optional().nullable(),
	job: jobSchema.optional().nullable(),
	organization: organizationSchema.optional().nullable(),
	latest_sequence: scheduledSequenceSchema.optional().nullable(),
	candidate: completeCandidateSchema,
	client_state: clientCandidateStateSchema.nullable(),
	internal_state: internalCandidateStateSchema.nullable(),
	is_deletable: z.boolean(),
})

export const paginatedListEamilAddressFetchSchema = z.object({
	entries: z.array(emailAddressFetchSchema),
	metadata: lisEmailAddressFetchParamsOutputSchema,
})

export const searchRunSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	status: searchRunStatusSchema.optional(),
	job_id: z.string(),
	job_information: searchRunJobInformationSchema.optional().nullable(),
	job_summary: highlightedJobSummarySchema.optional().nullable(),
	search_filters: z.object({}).passthrough().nullable(),
	search_results: z.object({}).passthrough().nullable(),
	candidate_scores: z.object({}).passthrough().nullable(),
	iterator_id: z.string().optional().nullable(),
	owner_id: z.string(),
	outreach_settings: searchRunOutreachSettingsSchema.nullable(),
	score_workflow: searchRunScoreWorkflowSchema.nullable(),
	owner_name: z.string().nullable(),
	owner_picture: z.string().nullable(),
})

export const paginatedListSearchesSchema = z.object({
	entries: z.array(searchSchema),
	metadata: listSearchParamsOutputSchema,
})

export const paginatedListUserEmailAccountSchema = z.object({
	entries: z.array(userEmailAccountSchema),
	metadata: listUserEmailAccountParamsOutputSchema,
})

export const paginatedListUserInvitesSchema = z.object({
	entries: z.array(
		z.union([userInviteSchema, userInviteWithRelationsSchema]),
	),
	metadata: listUserInviteParamsOutputSchema,
})

export const searchBundleSchema = z.object({
	job_summary: jobSummaryOutputSchema,
	children: z.array(childSearchSchema),
})

export const searchResultSchema = z.object({
	took: z.number().nullable(),
	total_count: z.number().nullable(),
	total_count_limit: totalCountLimitSchema.nullable(),
	max_score: z.number().optional().nullable(),
	es_query: z.object({}).passthrough().nullable(),
	candidates: z.array(searchResultCandidateSchema).nullable(),
})

export const createSearchDataSchema = z.object({
	body: createSearchRequestSchema,
})

export const listNotificationsDataSchema = z.object({
	body: listNotificationParamsInputSchema,
})

export const augmentedSearchResultSchema = z.object({
	took: z.number().nullable(),
	total_count: z.number().nullable(),
	total_count_limit: totalCountLimitSchema.nullable(),
	max_score: z.number().nullable(),
	es_query: z.object({}).passthrough().nullable(),
	candidates: z.array(augmentedSearchResultCandidateSchema).nullable(),
})

export const paginatedListSearchRunsSchema = z.object({
	entries: z.array(searchRunSchema),
	metadata: listSearchRunParamsOutputSchema,
})

export const parentSearchSchema = z.object({
	id: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	user_id: z.string(),
	job_id: z.string(),
	search_filters: fESearchFiltersOutputSchema,
	total_count: z.number().nullable(),
	total_count_limit: z.union([z.literal('eq'), z.literal('gte')]).nullable(),
	name: z.string().nullable(),
	starred: z.boolean().nullable(),
	iterator_mhid: z.string().nullable(),
	score_workflow: searchScoreWorkflowOutputSchema.nullable(),
	job_summary_id: z.string().nullable(),
	parent_search_id: z.string().nullable(),
	score_count: z.number().nullable(),
	score_offset: z.number().nullable(),
	scoring_bundles: z.array(searchBundleSchema),
})
